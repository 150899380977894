import {createBrowserRouter, Navigate} from "react-router-dom";
//import Login from "./components/Login.js";'
import Login from "./jsx/pages/Login";
import Login1 from "./components/Login1";
import Areas from "./components/city.js";
import StorageType from "./components/StorageType.js";
import ProcessStatus from "./components/ProcessStatus.js";
import Guaranteetype from "./components/GuaranteeType.js";
 import WeddingHall from "./components/WeddingHall.js";
import Empolyee from"./components/Employee.js";
import Orders from "./components/NewOrder.js";
import OrdersList from "./components/Orders.js";
import Alasnaf from "./components/Al-Asnaf.js";
import AsnafReport from "./components/AsnafReport.js";
import Tools from "./components/Tools.js";
import MainCompany from "./components/MainCompany.js";
import EditWeddingHall from "../src/jsx/constant/edit.js";
import ToolsReport from "./components/ToolsReport.js";
import Dashboard from "./components/Dashboard_1";
import MainPage from "./components/MainPage.js";
import GuestLayout from "./components/GuestLayout";
import CompanyExpenses from "./components/CompanyExpenses";
import NotFound from "./components/Not";
import Company from './components/Company.js';
import Invoice from './components/Invoice.js';
import OrderCheckout from './components/OrderCheckout';
import IntTypes from './components/IntTypes.js';
import ExpenseTypes from './components/ExpenseTypes.js';
import Departments from './components/Departments.js';
import Customers from './components/customers.js';
import Atbak from './components/Atbak.js';
import First from './components/charts/index.js';
import Ingredients from './components/Ingredients.js';
import CompanyPurchases from "./components/CompanyPurchases";
import Income from "./components/Income";
import NotConfirmedOrder from "./components/NotConfirmedOrder";
import NotConfirmedOrdercheckout from "./components/NotConfirmedOrdercheckout";
import NotConfirmedInvoice from "./components/NotConfirmedInvoice";
import EmployeeStatus from "./components/EmployeeStatus";
import Expensesreport from "./components/CompanyExpensesreport";
import FormValidation from "./components/FormValidation";
import DateLimitation from "./components/AllOrderDateLimits.js";
import ManageUsers from "./components/Orderss copy.js";
import EditCompany from "./components/Company copy.js";
import AddCompany from "./components/Company.js";
import ToolReport from "./components/toolReport.js";
import Garantees from "./components/Garantees.js";
import UserOrder from "./components/UserOrder.js";

const router = createBrowserRouter([
  {
    path: '',
    element: <MainPage/>,
    children: [
      {
        path: '',
        element: <Dashboard/>
      },
      {
        path: '/DateLimitation',
        element: <DateLimitation/>
      },
     
      {
        path: '/EmployeeStatus',
        element: <EmployeeStatus/>
      },
      {
        path: '/ToolReport',
        element: <ToolReport/>
      },
      {
        path: '/AddCompany',
        element: <AddCompany/>
      },
      {
        path: '/EditCompany',
        element: <EditCompany/>
      },
      {
        path: '/ManageUsers',
        element: <ManageUsers/>
      },
      {
        path: '/NotConfirmedOrder',
        element: <NotConfirmedOrder/>
      },
      {
        path: '/Garantees',
        element: <Garantees/>
      },
      {
        path: '/NotConfirmedInvoice',
        element: <NotConfirmedInvoice/>
      },
      {
        path: '/NotConfirmedOrdercheckout',
        element: <NotConfirmedOrdercheckout/>
      },
      {
        path: '/MainCompany',
        element: <MainCompany/>
      },
      {
        path: '/FormValidation',
        element: <FormValidation/>
      },
      {
        path: '/CompanyExpensesreport',
        element: <Expensesreport/>
      },
      {
        path: '/Ingredients',
        element: <Ingredients/>
      },
      {
        path: '/Atbak',
        element: <Atbak/>
      },
      {
        path: '/IntTypes',
        element: <IntTypes/>
      },
      {
        path: '/Income',
        element: <Income/>
      },
      {
        path: '/ExpenseTypes',
        element: <ExpenseTypes/>
      },
      {
        path: '/CompanyPurchases',
        element: <CompanyPurchases/>
      },
      {
        path: '/Departments',
        element: <Departments/>
      },
      {
        path: '/First',
        element: <First/>
      },
      {
        path: '/Dashboard_1',
        element: <Dashboard/>
      },
      {
        path: '/Invoice',
        element: <Invoice/>
      },
      {
        path: '/WeddingHall',
        element: <WeddingHall/>
      },
       {
        path: '/WeddingHall/edit/:id',
        element: <EditWeddingHall/>
      },
      {
        path: '/Customers',
        element: <Customers/>
      },
      {
        path: '/Company',
        element: <Company/>
      },
      {
        path: '/CompanyExpenses',
        element: <CompanyExpenses/>
      },
      {
        path:'/Tools',
  
        element: <Tools/>
      },
      {
        path: '/AsnafReport',
        element: <AsnafReport/>
      },
      {
        path: '/Empolyee',
        element: <Empolyee/>
      },
      {
        path: '/StorageType',
        element: <StorageType/>
      },
      {
        path: '/guaranteetype',
        element: <Guaranteetype/>
      },
      {
        path: '/ProcessStatus',
        element: <ProcessStatus/>
      },
      {
        path: '/ToolsReport',
        element: <ToolsReport/>
      },
      {
        path: '/Orders',
        element: <Orders/>
      },
      {
        path: '/OrdersList',
        element: <OrdersList/>
      },
      {
        path: '/Al-asnaf',
        element: <Alasnaf/>
      },
      {
        path: '/city',
        element: <Areas/>
      },
     
      {
        path: '/OrderCheckout',
        element: <OrderCheckout/>
      }
    ]
  },
  {
    path: '',
    element: <GuestLayout/>,
    children: [
      {
        path: '/login1',
        element: <Login1/>
      },
      {
        path: '/login',
        element: <Login/>
      },
      {
        path: '/UserOrder',
        element: <UserOrder/>
      },
     
    ]
  },
  {
    path: "*",
    element: <NotFound/>
  }
])

export default router;


