import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import InviteCustomer3 from './RestAmount';
import InviteCustomer5 from '../../components/InvoicePrint';
import InviteCustomer31 from './ArbonAmount';
const InviteCustomer = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [data1, setData1] = useState([]);
  const showInviteModal = () => {
    props.show(false)
    //setOrder('')
    setQun('')
    setAddress('')
    setIsRadioChecked1(true);
    setGuaranteeAmountdisplay(true) 
    setIsRadioChecked4(true)
  }
  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const form = e.target.form;
        const fields = Array.from(form.elements).filter(el => (
          (el.tagName === 'INPUT' && !el.disabled) ||
          (el.tagName === 'TEXTAREA' && !el.disabled) ||
          (el.tagName === 'SELECT' && !el.disabled)
        ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
        const nextFieldIndex = currentFieldIndex + 1;
        if (nextFieldIndex < fields.length) {
          fields[nextFieldIndex].focus();
        }
      }
    };
   

    document.addEventListener('keydown', handleEnterKey);
    
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, []);
  const [isRadioChecked5, setIsRadioChecked5] = useState(false);
  const [isRadioChecked4, setIsRadioChecked4] = useState(true);
  const [number, setnumber] = useState(0)

  const handleInputChange4 = () => {
    setIsRadioChecked4(!isRadioChecked4);


  }
  const InputChange1 = (e) => {
    const { value } = e.target;
    
    if (value != '')
   {const input = e.target.value;

    // Check if the input is a number or an empty string
    if (/^\d*$/.test(input)) {
      setIsRadioChecked5(true);
    
      setnumber(value)   
         setErrors1('')

    } else {
      setErrors1("هده الخانه لاتقبل حروف")

    }
  }
     
   
  else{
    
    const inputElement5 = document.getElementById('myinput5');
    inputElement5.value = '';
    setErrors1('')
     
     
    setIsRadioChecked5(false);
  
  }
  
 
   }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedDataArray = JSON.parse(searchParams.get('data')) || [];
  const receivedItemId = searchParams.get('id') || '';

  // const receivedData = location.state?.data || [];
  // console.log(receivedData)  
  console.log(receivedItemId)
  //  DeliveryID 	OrderID 	DeliveryPersonID 	DeliveryDate 	DelPrice 	Address 	Status 	UserID 
  const [OrderDate, setOrderDate] = useState(new Date());
  const [Discount, setDiscount] = useState('');
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
    console.log(userid,userid)

  const [order, setOrder] = useState({
    //  OrderID:'',
    CustomerID: receivedItemId,
    OrderDate: '',
    TotalAmount: '',
    DiscountAmount: '',
    Status: '',
    UserID: userid,
    // DeliveryID: '',
    DeliveryPersonID: '',
    DeliveryDate: '',
    DeliveryTime: '',
    DelPrice: '',
    Address: '',
    GuaranteeTypesID: '',
    GuaranteeAmount:'',
    DecoratePrice:'',
    OrderNotes:'',

  });
  
  const [lastorder, setlastorder] = useState(0)

  const [tools, settools] = useState([]);

  const [toolItems, settoolItems] = useState([]);

  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    getchalls();
    getcity();
    getGuaranteeType();
    getEmp();
    getLastorder();
    gettoolitem()

    setOrderItems(props.data2);

    // const intervalId = setInterval(() => {
    //   setOrderDate(new Date());
    //  }, 1000); // Update every 1000 milliseconds (1 second)

    // Cleanup function to clear the interval when the component unmounts
    //  return () => clearInterval(intervalId);

  }, [props.data2], [setOrderItems])
  const getLastorder = () => {
    try {
      axios.get('https://suhba.server.com.ly/back/api/getLastorder')
        .then(({ data }) => {
          setlastorder(data.OrderID);
          console.log("la", lastorder)
        })
    } catch (error) {
      console.error('Error fetching last customer:', error);
    }
  }   
         const [foodItems, setFoodItems] = useState([])
         const onSubmit2 =()=> {
          console.log("befor", order)
          const payload = {
             orderItems
        };
          axios.post(`https://suhba.server.com.ly/back/api/order_items`, payload)
            .then(() => {
              setOrder('')
               props.parentFunction()
      setQun('')
              props.show(false)
            })
            .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
      
        }
        const [address, setAddress] = useState([])
        const [isRadioChecked, setIsRadioChecked] = useState(true);

        const [isRadioChecked1, setIsRadioChecked1] = useState(true);
  const [isRadioChecked2, setIsRadioChecked2] = useState(true);
   const getfooditem = () => {
    axios.get('https://suhba.server.com.ly/back/api/getlast')
     .then(({ data }) => {
      setFoodItems(data.FoodItem)
     })}
     const gettoolitem = () => {
      axios.get('https://suhba.server.com.ly/back/api/gettoolitem')
       .then(({ data }) => {
        settools(data.ToolsItem)
       })}
  const onSubmit = ev => {
    ev.preventDefault()
    console.log("befor", order)
    const payload = {
      order
  };
    axios.put(`https://suhba.server.com.ly/back/api/new_orders/${order.OrderID}`, payload)
      .then(() => {
        setOrder('')
         props.parentFunction()

        props.show(false)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }

  const [errors2, setErrors2] = useState('')

  //OrderItemID 	OrderID 	ItemID 	TItemID 	Quantity 	GuaranteeTypesID 	UserID 
  const handleInputChange1 = (e) => {
    const input = e.target.value;

   
 
    const { name, value } = e.target;
    
    //////////////////////////////////////////////////
    if (name === "DelPrice") {
  if (/^\d*$/.test(input)) {
      setOrder((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      const subtotalNumber = parseInt(subtotal, 10) || 0;
      const itemPriceNumber = Number(value)

          setOrder((prevData) => ({
        ...prevData,
        TotalAmount: subtotalNumber+itemPriceNumber,
      }));
      setErrors('')

    } else {
      setErrors("هده الخانه لاتقبل حروف")

    }
    
    }
    //////
    if (name === "GuaranteeAmount") {
      if (/^\d*$/.test(input)) {
        setOrder((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors2('')
  
      } else {
        setErrors2("هده الخانه لاتقبل حروف")
  
      }
   

    }
    
    ///////////////////////////////////////////
    if (name === "HallID") {
 halls.map((item) => {
  if (item.HallID == value) {
    setOrder((prevData) => ({
      ...prevData,
      DelPrice: item.ItemPrice,
    }));
    setOrder((prevData) => ({
      ...prevData,
      Address: item.HallAddress,
    }));
    const subtotalNumber = parseInt(subtotal, 10) || 0;
    const itemPriceNumber = parseInt(item.ItemPrice, 10) || 0;
        setOrder((prevData) => ({
      ...prevData,
      TotalAmount: subtotalNumber+itemPriceNumber,
    }));

    setAddress(item.HallAddress);

    console.log('item.ItemPrice',item.ItemPrice)

    }})}
    /////////////////////////////////////////////
    if (name === "DiscountAmount") {

      setDiscount(value);

    }
    ////////////////////////////////////
    if (name === 'DecoratePrice') {
      if (/^\d*$/.test(input)) {
        setOrder((prevData) => ({
          ...prevData,
          [name]: number * value,
        }));
        setErrors1('')
  
      } else {
        setErrors1("هده الخانه لاتقبل حروف")
  
      }
      
    }
    ////////////////////////////
    setOrder((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrder((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Assuming you have an array of items you want to add

  // Use a loop to add items to orderItems

  console.log('recci', props.data2)
  const [subtotal, setsubtotal] = useState('');

  const [city, setCity] = useState([]);
  const [emp, setEmp] = useState([]);
  const [guaranteeType, setGuaranteeType] = useState([]);
  const [halls, setHalls] = useState([]);
  const getcity = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getcity').then(({ data }) => { setCity(data.area) })
  }
  const getEmp = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getEmp').then(({ data }) => { setEmp(data) })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
  const getGuaranteeType = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getGuaranteeType ').then(({ data }) => { setGuaranteeType(data.GuaranteeType) })
  }
  const getchalls = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getchalls ').then(({ data }) => { setHalls(data.HallName) })
  }
  console.log('ind', orderItems)
  //------------------------

  useEffect(() => {
    getexpensis();
    getfooditem();
    setOrderItems(props.data2);
    settoolItems(props.data3)
    setOrder(props.data)
    console.log('props.data',props.data);
    setsubtotal(props.data.TotalAmount-props.data.DelPrice)
  }, [props.data, setOrder]);
  console.log('props.viewModal', props.viewModal);




  const getexpensis = () => {
    axios.get('https://suhba.server.com.ly/back/api/fillexpeses')
      .then(({ data }) => {
        setData1(data.ExpenseType)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
  const handleRadioChange1 = () => {
    setIsRadioChecked1(false);
    const inputElement3 = document.getElementById('myinput3');
    inputElement3.value = '';
    setIsRadioChecked2(true);
    setIsRadioChecked(false);
    setAddress('')
    setOrder((prevData) => ({
      ...prevData,
      Address: order.Address,
    }));
    const inputElement2 = document.getElementById('myinput2');
     inputElement2.value = order.Address;
    
  };
  const handleRadioChange = () => {
    const inputElement = document.getElementById('myinput');
    inputElement.value = '';
    const inputElement1 = document.getElementById('myinput1');
    inputElement1.value = '';
    const inputElement2 = document.getElementById('myinput2');
    inputElement2.value = '';
    const inputElement3 = document.getElementById('myinput3');
    inputElement3.value = '';
    setOrder((prevData) => ({
      ...prevData,
     DelPrice: 0,
   }));
   setOrder((prevData) => ({
      ...prevData,
      Address: '',
    }));
    setOrder((prevData) => ({
      ...prevData,
     DeliveryPersonID: null,
   }));
   setOrder((prevData) => ({
    ...prevData,
   TotalAmount: subtotal,
 }));
    setIsRadioChecked(true);
    setIsRadioChecked2(true);
    setIsRadioChecked1(true)
  };
  const handleRadioChange2 = () => {
    const inputElement2 = document.getElementById('myinput2');
    inputElement2.value = '';
    setIsRadioChecked(false);
    setIsRadioChecked2(false);
    setIsRadioChecked1(true)
  //  const inputElement3 = document.getElementById('myinput3');
    //inputElement3.value = props.data.DelPrice;
  };
  const handleDelete = async (id) => {
    swal({
      title: "هل متأكد من الحدف",
      text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {

          axios.delete(`https://suhba.server.com.ly/back/api/order_items/${id}`)

            .then(res => {
              setOrderItems(orderItems.filter(resource => resource.OrderItemID !== id)); // Assuming resources is your state containing the table data
              settoolItems(toolItems.filter(resource => resource.OrderItemID !== id)); // Assuming resources is your state containing the table data
              setOrder('')
              props.parentFunction()
     setQun('')
              swal({
                title: "تم",
                text: "تم الحدف بنجاح",
                icon: "success",
                timer: 2000,
                button: false
              })
              navigate('#')


            });

        } else {
          swal("تم الغاء عملية الحدف");
        }

      });

  };
  //---------------------------------------------
  const [qun, setQun] = useState(0); 
   const [errors, setErrors] = useState('')
   const [errors1, setErrors1] = useState('')

  const [TItemPrice, setTItemPrice] = useState('');
  const [TItemID, setTItemID] = useState('');
  const [TitemName, setTItemName] = useState('');
  const [ItemPrice, setItemPrice] = useState('');
  const [ItemID, setItemID] = useState('');
  const [itemName, setItemName] = useState('');
  const Tmanimise =(ItemID ,itemName,qun) =>{
    let updatedList;
    const qunAsNumber = parseInt(qun, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
    const itemExists = toolItems.some(
      (item) => item.TName === itemName && item.TItemID === ItemID
    );

    if (itemExists) {
      updatedList = toolItems.map((item) => {
        if (item.TName === itemName && item.TItemID === ItemID) {
          return {
            ...item,
           // aa: item.aa + cal,
            Quantity:  qunAsNumber,
          };
        }
        return item;
      });
      settoolItems(updatedList);

    } 
  }
  const manimise =(ItemID ,itemName,qun) =>{
    let updatedList;
    const qunAsNumber = parseInt(qun, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
    const itemExists = orderItems.some(
      (item) => item.Name === itemName && item.ItemID === ItemID
    );

    if (itemExists) {
      updatedList = orderItems.map((item) => {
        if (item.Name === itemName && item.ItemID === ItemID) {
          return {
            ...item,
           // aa: item.aa + cal,
            Quantity:  qunAsNumber,
          };
        }
        return item;
      });
      setOrderItems(updatedList);

    } 
  }
  const listItemfun = (e) => {
   
 console.log('e.target.value',e.target.value)
    foodItems.map((item) => {
      if (e.target.value == item.ItemID) {
setItemID(item.ItemID)
setItemName(item.Name)
setItemPrice(item.ItemPrice)
console.log('e.target.value',item.ItemPrice)

      }
  })
  }
  const handleInputChange11 = (e) => {
    const input = e.target.value;

    // Check if the input is a number or an empty string
    if (/^\d*$/.test(input)) {
      setQun(input);
      setErrors('')

    } else {
      setErrors("هده الخانه لاتقبل حروف")

    }
  };
  const listClickHandler = () => {
     if (qun === '') {
      setErrors("يجب ادخال الكمية")

    } else {
      if (/^\d*$/.test(qun)) {
      
        const calc = 0;
        let cal = ItemPrice * qun;
        let updatedList;
        const qunAsNumber = parseInt(qun, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
        const itemExists = orderItems.some(
          (item) => item.Name === itemName && item.ItemID === ItemID
        );

        if (itemExists) {
          updatedList = orderItems.map((item) => {
            if (item.Name === itemName && item.ItemID === ItemID) {
              return {
                ...item,
               // aa: item.aa + cal,
                Quantity: item.Quantity + qunAsNumber,
                Total:item.ItemPrice * qunAsNumber
              };
            }
            return item;
          });
        } else {
          updatedList = [
            ...orderItems,
            { Name: itemName, ItemPrice: ItemPrice, Quantity: qunAsNumber, ItemID: ItemID ,OrderID:order.OrderID ,UserID:userid ,Total:ItemPrice*qunAsNumber},
          ];
        }

        setOrderItems(updatedList);
      //   setQun('');
         setErrors('')

      
    }  else {
      setErrors("هده الخانه لاتقبل الا الاعداد الصحيحة")

    }
  }}
  const listItemfun2 = (e) => {
   
    console.log('e.target.value',e.target.value)
       tools.map((item) => {
         if (e.target.value == item.TItemID) {
   setTItemID(item.TItemID)
   setTItemName(item.TName)
   setTItemPrice(item.TItemPrice)
   console.log('e.target.value',item.TName)
   
         }
     })
     }
  const handleInputChange112 = (e) => {
    const input = e.target.value;

    // Check if the input is a number or an empty string
    if (/^\d*$/.test(input)) {
      setQun(input);
      setErrors('')

    } else {
      setErrors("هده الخانه لاتقبل حروف")

    }
  };
  const listClickHandler2 = () => {
     if (qun === '') {
      setErrors("يجب ادخال الكمية")

    } else {
      if (/^\d*$/.test(qun)) {
      
        const calc = 0;
        let cal = ItemPrice * qun;
        let updatedList;
        const qunAsNumber = parseInt(qun, 10) || 0; // Convert qun to a number, defaulting to 0 if conversion fails
        const itemExists = toolItems.some(
          (item) => item.TName === TitemName && item.TItemID === TItemID
        );

        if (itemExists) {
          updatedList = toolItems.map((item) => {
            if (item.TName === TitemName && item.TItemID === TItemID) {
              return {
                ...item,
               // aa: item.aa + cal,
                Quantity: item.Quantity + qunAsNumber,
                Total:item.TItemPrice * qunAsNumber
              };
            }
            return item;
          });
        } else {
          updatedList = [
            ...toolItems,
            { TName: TitemName, TItemPrice: TItemPrice, Quantity: qunAsNumber, TItemID: TItemID ,OrderID:order.OrderID ,UserID:userid ,Total:TItemPrice*qunAsNumber},
          ];
        }

        settoolItems(updatedList);
      //   setQun('');
         setErrors('')

      
    }  else {
      setErrors("هده الخانه لاتقبل الا الاعداد الصحيحة")

    }
  }}
  const [GuaranteeAmountdisplay, setGuaranteeAmountdisplay] = useState(true);

  const handleInputChange13 = (e) => {
    // Your additional logic here
    const { name, value } = e.target; 

    if (name === 'GuaranteeTypesID') {
      if(value == 3)
    {  setGuaranteeAmountdisplay(false)}else{ setGuaranteeAmountdisplay(true) 
      setOrder((prevData) => ({
        ...prevData,
        GuaranteeAmount: 0,
      }));

    }
      console.log(value)
    }  };

  const handleSelectChange = (ev) => {
    setOrder({ ...order, GuaranteeTypesID: ev.target.value });
    handleInputChange13(ev);
  };
  const onSubmit22 =()=> {
    console.log("befor", order)
    const payload = {
       toolItems
  };
    axios.post(`https://suhba.server.com.ly/back/api/storetool`, payload)
      .then(() => {
        setOrder('')
         props.parentFunction()
setQun('')
        props.show(false)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
  console.log('order',order)
  //////////////////////////////////////////////////////////////////////////////////////
  const [report, setreport] = useState({})
  const [inviteModal5, setInviteModal5] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [foodIteming, setFoodIteming] = useState([])
  const invite5 = useRef();
  const invite31 = useRef();
  const invite3 = useRef();
  const invite1= useRef();
  const invite = useRef();

  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [toolitem, settoolitem] = useState([])
  const [data3, setData3] = useState({
    amount: '',
    CustomerName: '',
    deposit_date: '',
    reference_number: '',
    deposits: '',
    reastAmount: '',
  });
  const show = (value) => {
    //setFoodIteming('')
    setInviteModal(value);
  }
  function EditData(id, type) {

  
        setData2(order)
        setFoodIteming(order.orderItems)
        settoolitem(order.toolItems)
    
          // setInviteModal2(true)
          invite5.current.showInviteModal()
          console.log('vvvvvvvvvvvv', "item.orderItems");

       
 



  }
  const EditData55 = async (id) => {
    console.log('idedit', id);

     const response = await axios.get(`https://suhba.server.com.ly/back/api/getdiposit/${id}`);
    const depositData = response.data.Deposit;
    if (depositData) {
      setData3(depositData)
      
          const newObj = {
            amount: depositData.amount,
            CustomerName: order.CustomerName,
            deposit_date: depositData.deposit_date,
            reference_number: depositData.reference_number,
            deposits: depositData.deposits,
            reastAmount: (order.TotalAmount - order.Prepay)
          };

          console.log('data3', newObj)

          setreport(newObj)
       

      invite31.current.showInviteModal()

    } else {
      swal(" هده الطلبية ملغية");

    }




    //setID(id); setInviteModal(true)
    //getfooditeming(e, id)

    // invite3.current.generatePDF();
  }
  const EditData556 = async (e, id) => {
    console.log('idedit', id);
    const response = await axios.get(`https://suhba.server.com.ly/back/api/getdipositfullpayed/${id}`);
    const depositData = response.data.Deposit;
    const ArboonData = response.data.arboon;
    console.log('vvvvvvdepositDatavvvvvv', depositData);

    let singleamount = 0;
    if (depositData.length > 0) {
      setData3(depositData);

      // Map through depositData to create new objects
      const mappedData = depositData.map(deposit => {
        // Find the corresponding item in data based on OrderID
        const correspondingItem = data.find(item => item.OrderID === deposit.OrderID);
        singleamount += parseInt(deposit.amount);
        console.log('singleamount', singleamount)
        const reastAmount = correspondingItem ? (correspondingItem.TotalAmount - ArboonData.amount - singleamount) : null;

        // Create a new object combining data from both sources
        return {
          amount: deposit.amount,
          CustomerName: correspondingItem ? correspondingItem.CustomerName : null,
          deposit_date: deposit.deposit_date,
          reference_number: deposit.reference_number,
          deposits: deposit.deposits,
          reastAmount: reastAmount
        };
      });

      console.log('mappedData', mappedData);

      // Update report state with mapped data
      setreport(mappedData);

      invite3.current.showInviteModal()
    } else {
      swal(" لم يتم الدفع بعد");

    }
  }

  ///////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div>

      </div>
      <Modal className="fade bd-example-modal-lg " size="lg" id="exampleModal1" show={props.inviteModal} centered> 


        <div className="modal-content"style={{ height: "550px" }}>
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">تعديل بيانات الطلبية  </h1>
            <button type="button" className="btn-close " style={{ marginLeft: 0 }} padding="" onClick={() => showInviteModal()}></button>
          </div>
          <div className="px-0 pb-0">
            <div className="card-body ">
              <Tab.Container defaultActiveKey={'Social'}>
                <Nav as="ul" className="nav nav-pills success-tab" id="pills-tab" role="tablist">
                  <Nav.Item as="li" >
                    <Nav.Link as="button" className='p-0' eventKey={'Social'} style={{ width: "100px" }}>
                      <span>بيانات الطلبية</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" >
                    <Nav.Link as="button" className='p-0'  eventKey={'Project'} style={{ width: "100px" }}>
                      <span>اصناف الطلبية</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" >
                    <Nav.Link as="button" className='p-0' eventKey={'Sale'} style={{ width: "100px" }}>
                      <span>معدات الطلبية</span>
                    </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" >

                    <Nav.Link as="button" className='p-0' eventKey={'files'} style={{ width: "100px" }}>
                      <span> ملفات  </span>
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey={'Social'} style={{ height: "300px" }}>
                    <div className="col-xl-12">

                      <div className="row">

                       
                        
                        <div className="col-xl-2 mb-1">
                          <label htmlFor="exampleFormControlInput99" className="form-label" > تاريخ  الاستلام </label>
                        </div>
                        <div className="col-xl-4 mb-1">
                          <input
                            type="date" disabled={props.viewModal}                           className="form-control ms-3 " style={{ float: "right" }}
                            id="same-address" Name="DeliveryDate"

                            defaultValue={order.DeliveryDate} onChange={(ev) => setOrder({ ...order, DeliveryDate: ev.target.value })}

                          />
                        </div>


                        <div className="col-xl-2 mb-1">
                          <label htmlFor="exampleFormControlInput99" className="color-time-picker" >توقيت الطلبية </label>
                        </div>
                        <div className="col-xl-4 mb-1 " style={{ direction: "ltr" }}>
                          <input
                            type="time"
                            className="form-control ms-3 " style={{ float: "right" }}
                            id="same-address"
                            Name="DeliveryTime"
                            defaultValue={order.DeliveryTime} onChange={(ev) => setOrder({ ...order, DeliveryTime: ev.target.value })}
                            disabled={props.viewModal}
                          /></div></div>

                   

                      <div className="row">
                        <div className="col-xl-12 mb-3">
 
                               <div className="row">

                                <div className="col-xl-2 mb-1">
                                  <label className="form-label mt-3">نوع الضمان</label>
                                </div>
                                <div className="col-xl-4 mb-1 mt-3">
                                  <select className="default-select form-control" name="GuaranteeTypesID" value={order.GuaranteeTypesID}
                                         onChange={handleSelectChange}
                                  disabled={props.viewModal}>
                                    <option value="" data-display="Select">
                                      {order.GuaranteeTypesID}
                                    </option>                                 {
                                      guaranteeType.map((row) => (
                                        
                                        <option key={row.GuaranteeTypesID} value={row.GuaranteeTypesID}>
                                          {row.GuaranteeTypesName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-xl-2 mb-1">
                                <label className="form-label mt-3"   hidden={GuaranteeAmountdisplay}>القيمة المالية</label>

                              </div>
                                      <div className="col-xl-4 mb-1">

                                        <input type="text" placeholder="القيمة"
                                        hidden={GuaranteeAmountdisplay} className="form-control mt-3" name="GuaranteeAmount"  required defaultValue={order.GuaranteeAmount} onChange={handleInputChange1} />
 {errors2 ? <div style={{height: '20px',

paddingtop: '0px',width: '120px',
}}className=' alert-danger'>{errors2}</div> : ""}
                                      </div>
                                      <fieldset className="form-group">
                              <div className="row ">

                                <div className="col-sm-3">
                                  <div className="form-check mb-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="gridRadios"
                                      value="option1"
                                      disabled={props.viewModal}
                                      onChange={handleRadioChange}
                                     />
                                    <label className="form-check-label">
                                      الاستلام من المحل
                                    </label>
                                  </div> </div>
                                <div className="col-sm-3">

                                  <div className="form-check mb-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="gridRadios"
                                      value="option2"
                                      disabled={props.viewModal}
                                      onChange={handleRadioChange2} />

                                    <label className="form-check-label">
                                       من الصالة
                                    </label>
                                  </div>

                                </div>
                                <div className="col-sm-3">



                                <div className="form-check mb-4">

                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="gridRadios"
                                          value="option2"
                                          disabled={props.viewModal}
                                          onChange={handleRadioChange1} />

                                        <label className="form-check-label">
                                          الى العنوان </label>
                                          </div> </div>
                                      <div className="col-xl-1  mb-4">
                                       <div className="row" style={{display:'flex'}}> 
                                       <label hidden={isRadioChecked1} className="form-check-label">
                                   السعر</label>
                                   </div> </div>
                                       <div className="col-xl-2  ">

                                          <input required type="text" 
                                         hidden={isRadioChecked1} id="myinput3" className="form-control" name="DelPrice" defaultValue={order.DelPrice} onChange={handleInputChange1} />
                                     {errors ? <div style={{height: '20px',

  paddingtop: '0px',width: '120px',
  }}className=' alert-danger'>{errors}</div> : ""}
                                      </div> 
                         

                               
                              </div>
                            </fieldset>
                                <div className="col-xl-2 mb-1">
                                  <label className="form-label mt-3">المندوب</label>
                                </div>
                                <div className="col-xl-4 mb-1 mt-3">
                                  <select disabled={props.viewModal || isRadioChecked} className="default-select form-control" id="myinput" name="DeliveryPersonID" value={order.DeliveryPersonID} onChange={(ev) => setOrder({ ...order, DeliveryPersonID: ev.target.value })}
                                 required >
                                    <option data-display="Select">اختر من القائمة</option>                               {
                                      emp.map((row) => (
                                        <option key={row.EmployeeID} value={row.EmployeeID}>
                                          {row.FirstName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-xl-2 mb-1">
                                  <label className="form-label   mt-3" htmlFor="address">العنوان</label>
                                </div>
                                <div className="col-xl-4 mb-1 mt-3">

                                  <textarea disabled={props.viewModal || isRadioChecked1} rows="2" class="form-control h-100 col-xl-8 mb-1" name="Address"  id="myinput2" defaultValue={order.Address} onChange={(ev) => setOrder({ ...order, Address: ev.target.value })}></textarea>
                                </div>
                                <div className="col-xl-2 mb-1">

                                  <label className="form-label   ">قاعة الافراح</label>
                                </div>
                                <div className="col-xl-4 mb-1">

                                  <select disabled={props.viewModal || isRadioChecked2} className="default-select form-control  " id="myinput1" name="HallID" defaultValue={order.HallID}  onChange={handleInputChange1} >
                                    <option data-display="Select">اختر من القائمة</option>
                                    {
                                      halls.map((row) => (
                                        <option key={row.HallID} value={row.HallID}>
                                          {row.HallName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-xl-2">
                                <label htmlFor="state">عنوانها</label>
                              </div>
                              <div className="col-xl-4 mb-1">

                                <label className="form-label "> {address} </label>


                              </div>
                               <div className="col-xl-2 mb-1">
        <label htmlFor="exampleFormControlInput99" className="form-label" >ملاحظات <span className="text-danger"></span></label>                                    
        </div><div className="col-xl-4 mb-1">
        <textarea className="form-control ms-3 " id="exampleFormControlTextarea1" rows="3"
          defaultValue={order.OrderNotes} onChange={handleInputChange1}Name="OrderNotes"

                                        ></textarea>
       
     
        </div> 
                              </div>
                              <div className="row mt-2 mb-1">
    <div className="col-xl-2  ">
  <input
    className="form-check-input"
    type="checkbox"
    name="gridRadios"
    value="option2"
    disabled={props.viewModal}
  onChange={handleInputChange4}                            />

  <label className="form-check-label me-2">
  تزين الصحن </label></div>
<div className="col-xl-10 ">
<div className="row">

<div className="col-xl-1 ">

<label hidden={isRadioChecked4} className="form-check-label">
لعدد</label></div>
<div className="col-xl-5">
 <input type="text"
required hidden={isRadioChecked4} id="myinput4" className="form-control" name="DecoratePrice" defaultValuee={order.DecoratePrice} onChange={InputChange1}  />
</div>
<div className="col-xl-1 ">

<label hidden={isRadioChecked4} disabled={props.viewModal ||!isRadioChecked4} className="form-check-label">
بسعر</label>
</div>
<div className="col-xl-5 ">

<input required type="text"disabled={props.viewModal ||!isRadioChecked5}  hidden={isRadioChecked4} id="myinput5" className="form-control" name="DecoratePrice" defaultValuee={order.DecoratePrice} onChange={handleInputChange1}  />
</div>
{errors1 ? <div style={{height: '20px',
  paddingTop: '0px',
 }}className=' alert-danger'>{errors1}</div> : ""}

</div>

</div>

</div>

                            <hr className="mb-2 mt-3" />


                            <Link
                              className="btn btn-primary btn-sm   "
                              to="/Invoice" onClick={onSubmit}>

                              حفظ التعديلات
                            </Link>
                          </div>
 
                      </div>




                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey={'Project'} style={{ height: "300px" }}>
                    <div className="row">
                            <div className="col-xl-3">
                                <label className="form-label mt-3">اسم الصنف</label>
                                 <select className="default-select form-control" name="FoodItemID"onChange={(e) =>
                                  listItemfun(e)
                                  } >
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   foodItems.map((row)=>(
                                  <option key={row.ItemID} value={row.ItemID}>
                                            {row.Name}
                                    </option>
                                     ))}    
                                 </select> 
                                   </div>
                                   <div className="col-xl-2">
                                <label className="form-label mt-3"> الكمية</label>
                                <input type="text"
                className="form-control"
                value={qun}

                //placeholder="ادخل قيم عددية صحيحه  فقط"
                required
                id="inputcity"
                name="city"
                onChange={handleInputChange11} ></input>                     
                                
                                 </div>
                                 <div className="col-xl-7" style={{display:'flex'}}>
                                 <button  id="toastr-success-top-left" className="btn btn-primary  mt-5 btn-icon-xs  mx-2 "
                                 onClick={() => listClickHandler()}
                                 >+</button>
                                 
                                 <button  id="toastr-success-top-left  " className="btn btn-primary  mt-5 btn-icon-xs  mx-2"
                                 onClick={() => onSubmit2()}
                                 >حفظ التعديلات</button>
                                 </div>
                      <div className="table-responsive active-projects dlab-scroll mt-3" style={{ height: "200px" }}>
                     
                        <table id="projects-tbl4" className="table">
                          <thead>
                            <tr>
                              <th>اسم الصنف</th>
                              <th>الكمية</th>
                         
                    
                              <th>السعر</th>

                              <th>إلغاء</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderItems.map((item, ind) => (
                              <tr key={ind}>


                                <td>{item.Name}</td>
                                <td contentEditable
                 onBlur={(e) => manimise(item.ItemID, item.Name,e.target.textContent)}>
                                  {item.Quantity}
                                  </td>
                                <td>{item.ItemPrice}</td>
                               <td>
                                <Link style={{ pointerEvents: props.viewModal ? 'none' : 'auto', color: props.viewModal ? 'grey' : 'inherit' }} onClick={() => handleDelete(item.OrderItemID)}>
    <i className="fas fa-trash-alt"></i>
</Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey={'Sale'} style={{ height: "300px" }}>
                    <div className="row">
                    <div className="col-xl-3">
                                <label className="form-label mt-3">اسم الاداة</label>
                                 <select className="default-select form-control" name="FoodItemID"onChange={(e) =>
                                  listItemfun2(e)
                                  } >
                                 <option  data-display="Select">اختر من القائمة</option>
                                 {
                                   tools.map((row)=>(
                                  <option key={row.TItemID} value={row.TItemID}>
                                            {row.TName}
                                    </option>
                                     ))}    
                                 </select> 
                                   </div>
                                   <div className="col-xl-3">
                                <label className="form-label mt-3"> الكمية</label>
                                <input type="text"
                className="form-control"
                value={qun}

                //placeholder="ادخل قيم عددية صحيحه  فقط"
                required
                id="inputcity"
                name="city"
                onChange={handleInputChange112} ></input>                     
                                
                                 </div>
                                 <div className="col-xl-3" style={{display:'flex'}}>
                                 <button  id="toastr-success-top-left" className="btn btn-primary  mt-5 btn-icon-xs  mx-2 "
                                 onClick={() => listClickHandler2()}
                                 >+</button>
                                 
                                 <button  id="toastr-success-top-left  " className="btn btn-primary  mt-5 btn-icon-xs  mx-2"
                                 onClick={() => onSubmit22()}
                                 >حفظ التعديلات</button>
                                 </div>
                      <div className="table-responsive active-projects dlab-scroll mt-3" style={{ height: "200px" }}>
                        <table id="projects-tbl4" className="table">
                          <thead>
                            <tr>
                              <th>اسم الاداة</th>
                              <th>الكمية</th>
                              <th>السعر</th>

                              <th>إلغاء</th>

                            </tr>
                          </thead>
                          <tbody>
                            {toolItems.map((item, ind) => (
                              <tr key={ind}>


                                <td>{item.TName}</td>
                                <td   contentEditable
                 onBlur={(e) => Tmanimise(item.TItemID, item.TName,e.target.textContent)}>
                                {item.Quantity}</td>
                                <td>{item.TItemPrice}</td>


                                <td>
                                <Link style={{ pointerEvents: props.viewModal ? 'none' : 'auto', color: props.viewModal ? 'grey' : 'inherit' }} onClick={() => handleDelete(item.OrderItemID)}>
    <i className="fas fa-trash-alt"></i>
</Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey={'files'} style={{ height: "300px" }}>
                  <div className="row">
                  <div className="col-xl-4 col-xxl-3 col-md-4 col-sm-6 ">
                                      <div className="card p-3">
                                          <div className="col-lg-12">
                                            <div className="col-sm-12">
                                              <div className="new-arrival-content text-center ">
                                                <h5>
                                                  الفاتورة</h5>
                                                  <Link dir="rtl" className=""
                            to={"#"} onClick={(e) => EditData(order.OrderID)}
                           > <i class="fa-solid fa-file-lines fa-2x"></i>
                          </Link>
                                              </div>
                                            </div>
                                          </div>
                                      </div>

                                    </div>
                                    <div className="col-xl-4 col-xxl-3 col-md-4 col-sm-6" >
                                      <div className="card p-3">
                                          <div className="col-lg-12">
                                            <div className="col-sm-12">
                                              <div className="new-arrival-content text-center ">
                                                <h5>
                                                  إصال العربون</h5>
                                                  <Link dir="rtl" className=""
                            to={"#"}onClick={(e) => EditData55(order.OrderID)}
                           > <i class="fa-solid fa-file-lines fa-2x"></i>
                          </Link>
                                              </div>
                                            </div>
                                          </div>
                                      </div>

                                    </div> 
                                    <div className="col-xl-4 col-xxl-3 col-md-4 col-sm-6" >
                                      <div className="card p-3">
                                          <div className="col-lg-12">
                                            <div className="col-sm-12">
                                              <div className="new-arrival-content text-center ">
                                                <h5>
                                                  إصال باقي القيمة</h5>
                                                  <Link dir="rtl" className=""
                            to={"#"} onClick={(e) => EditData556(order.OrderID)}
                           > <i class="fa-solid fa-file-lines fa-2x"></i>
                          </Link>
                                              </div>
                                            </div>
                                          </div>
                                      </div>

                                    </div> 
                                   
                                    </div>
                  </Tab.Pane>

                </Tab.Content>
              </Tab.Container>
            </div>

          </div>
        </div>

      </Modal>
      <InviteCustomer5
        ref={invite5}

        data={data2}
        data2={foodIteming}
        data3={toolitem}

         inviteModal={inviteModal5}
        viewModal={viewModal}
        show={show} />

      
      <InviteCustomer3
        ref={invite3} data={report}
      />
      <InviteCustomer31
        ref={invite31} data={report}
      />
    </>
  )
})
export default InviteCustomer;