import React from 'react';
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import Employee from './Employee';
import { Modal } from 'react-bootstrap';

const EmpData = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [adress, setAddress] = useState('')
  const [Datadepartment, setDatadepartment] = useState([])
  const [employee, setemployee] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [empdata, setempdata] = useState('')

  const [imageDate, setimageDate] = useState('')
  const [ImageName, setImageName] = useState('')
  const [Salary, setSalary] = useState('')
  const [DepartmentID, setDepartmentID] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('')
  const [WorkTime, setWorkTime] = useState('')
  const [ToWorkTime, setToWorkTime] = useState('')
  const [PhoneNumber1, setPhoneNumber1] = useState('')
  const [EmpStatus, setEmpStatus] = useState('')
  const [HireType, setHireType] = useState('')
  const [HireDate, setHireDate] = useState('')
  const [ToHireDate, setToHireDate] = useState('')
  const [FirstName, setFirstName] = useState('')
  const [EmployeeID, setEmployeeID] = useState('')



  const [UserID, setUserID] = useState('')
  const [inviteModal, setInviteModal] = useState(false);
 
console.log('props.data',props.data)
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
  const userid = userData.id;
  const showInviteModal = () => {   
    setInviteModal(false)   
   setNewComemp('')
   }
  const [newComemp, setNewComemp] = useState({});
  useEffect(() => {
  
      setNewComemp(props.data);
    
  }, [props.data,setNewComemp]);
  //'FirstName' ,	'' ,	'PhoneNumber1' ,	'DepartmentID' , imageDate
  //	'' 	,'' ImagePath
  //	,'WorkTime' ,'ToWorkTime' 	EmpStatus,'' ,	'HireType' ,HireDate	'ToHireDate' ,
  //'ImageName' ,	'' 	,'  ,	'' ,	'UserID' 	];
  useEffect(() => {
    getdepartment();
    getemployee()
    
  }, [])
  

  const getdepartment = () => {
    axios.get('https://suhba.server.com.ly/back/api/departments')
      .then(({ data }) => {
        console.log(data)
        setDatadepartment(data)
      })
  }
  const getemployee = () => {
    axios.get('https://suhba.server.com.ly/back/api/employee_status_types')
      .then(({ data }) => {
        console.log(data)
        setemployee(data)
      })
  }
  
const handleEdit1 = async (id, field, value) => {
  try {
    // Create a new FormData object
    const formData = new FormData();
    
    // Append the field and value to the FormData object
    formData.append(field, value);

    // If updating an image, the field should correspond to the image field in your backend
    // Example: if 'value' is an image file and 'field' is 'profileImage'
    // formData.append('profileImage', value);

    // Send a PUT request with the FormData object
    const response = await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle success
    console.log('Update successful:', response.data);
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error updating:', error);
    throw error;
  }}  
     const [errors, setErrors] = useState('')
     const handleEdit2 = async (id, field, value) => {
      if (/^\d*$/.test(value)) {
   
        const error = await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${id}`, {
          field: field,
          value: value,
      });
           if (error.response) {
             console.log(error.response.data);
             console.log(error.response.status);
             console.log(error.response.headers);
           } else if (error.request) {
             console.log(error.request);
           } else {
             console.log('Error', error.message);
           }
           console.log(error.config);
           props.fun()
        setErrors('')
  
      } else {
        setErrors("هده الخانه لاتقبل حروف")
  
      }
     
        
      
      } 
      const handleEdit = async (id, field, value) => {
        
    
        try {
          const response = await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${id}`, {
            [field]: value,  });
          props.fun();
        } catch (error) {
          console.error('Error updating:', error);
        }
      };
      const [photoPreview, setPhotoPreview] = useState(null); // Photo preview state

      const handleFileChange = (e,id) => {
        const file = e.target.files[0];
   

    // Preview the uploaded image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPhotoPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setPhotoPreview(null);
    }
        setSelectedFile(e.target.files[0]);
        const formData = new FormData();
        formData.append('EmployeeID', id);

           formData.append('ImageName', e.target.files[0]);
           try {
            const response = axios.post(`https://suhba.server.com.ly/back/api/CompEmployee`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            props.fun();
          } catch (error) {
            console.error('Error updating:', error);
          }
       };
  const handleEditold = async (id, field, value) => {
    console.log(field)
    console.log(value)

    if ( field==='PhoneNumber' || field ==='PhoneNumber1' )
     {if (!value || !value.match(/^(218)?09[123456][0-9]{7}$/)
      || (value && !value.match(/^(218)?09[123456][0-9]{7}$/)))
        {
    
setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
console.log(errors)
    }else{
      setErrors('')

    const error = await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${id}`, {
      field: field,
      value: value,
  });
       if (error.response) {
         console.log(error.response.data);
         console.log(error.response.status);
         console.log(error.response.headers);
       } else if (error.request) {
         console.log(error.request);
       } else {
         console.log('Error', error.message);
       }
       console.log(error.config);
       props.fun()
      }}else{
        const error = await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${id}`, {
          field: field,
          value: value,
      });
           if (error.response) {
             console.log(error.response.data);
             console.log(error.response.status);
             console.log(error.response.headers);
           } else if (error.request) {
             console.log(error.request);
           } else {
             console.log('Error', error.message);
           }
           console.log(error.config);
           props.fun()
      }
    } 
  // StatusTypeID 	StatusName employee_status_types
  const changeHandler = async () => {
    console.log(newComemp.ImageName);

    
 

          await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${newComemp.EmployeeID}`,newComemp 
          , {
            headers: {
              'Content-Type': 'multipart/form-data',
            },})
       
}

  const createEmployee =  () => {
  /*  e.preventDefault();
    const formData = new FormData();
    formData.append('FirstName', FirstName)
    formData.append('PhoneNumber', PhoneNumber)
    formData.append('PhoneNumber1', PhoneNumber1)
    formData.append('DepartmentID', DepartmentID)
    formData.append('imageDate', imageDate)
    formData.append('Salary', Salary)
    formData.append('WorkTime', WorkTime)
    formData.append('ToWorkTime', ToWorkTime)
    formData.append('EmpStatus', EmpStatus)
    formData.append('ToHireDate', ToHireDate)
    formData.append('HireDate', HireDate)
    formData.append('UserID', userid)

    formData.append('HireType', HireType)
    formData.append('ImagePath', adress)

    formData.append('ImageName', ImageName)


    console.log(formData)
    await axios.put(`https://suhba.server.com.ly/back/api/CompEmployee/${newComemp.EmployeeID}`, formData)
      .then(res => {
*/
        swal({
          title: 'تم عملية الحفظ',
          timer: 2000,  // Duration in milliseconds (2000 ms = 2 seconds)
          icon: 'success'  // Optional: to show an icon (e.g., 'success', 'error', 'warning', 'info')

        })
       setInviteModal(false)
    /*  })

      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
      ;*/
  }




  const radiocheckBox = [
    { title: '  مرتب', check: 'primary' }, { title: '   يومي', check: 'secondary' },
  ];
  const radiocheckBox2 = [
    { title: '  يعمل', check: 'primary' }, { title: '   في إجازة', check: 'secondary' }, { title: '   انهاء العمل', check: 'secondary' }, { title: ' مفصول', check: 'secondary' },
  ];
  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setInviteModal(true)
    }
  }));


  return (
    <Modal className="modal fade  ps--scrolling-y" size="xl" id="exampleModal1"  show={inviteModal} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel1">{props.Title}</h1>
          <button type="button" className="btn-close " style={{ marginLeft: 0 }} padding="" onClick={()=>showInviteModal()}></button>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card" style={{border:'0px', marginTop:'20px'}}>

                  <div className="row">
                  <div className="col-xl-12">
                        {errors? <div className=' alert alert-danger'>{errors}</div> : ""}
                        </div> 
                        <div className="col-xl-2">
                    <div className="blog-img image-hight" style={{ border: 'black dashed 1px', height: '200px' }}>
                {photoPreview ? (
  <img src={photoPreview} alt="Photo preview" style={{ height: '200px', width: '100%' }} />
) : props.data.ImageName ? (
  <img src={`https://suhba.server.com.ly/back${props.data.ImageName}`}   style={{ height: '200px', width: '100%' }} />
) : (
  <div style={{ height: '200px', width: '100%', backgroundColor: '#ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    لا توجد صورة
  </div>
)}


                </div>
                    <input className="form-control" type="file" id="formFile" name='ImageName' onChange={(e) =>handleFileChange(e,props.data.EmployeeID)} />
                  </div>
                    <div className="col-xl-10 ">
                    <div className="row">

                    <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput1" className="form-label">اسم الموظف <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable}  type="text" className="form-control" id="exampleFormControlInput1" placeholder="" name='FirstName'
                            defaultValue={newComemp.FirstName}
                            onBlur={(e) => handleEdit(props.data.EmployeeID, 'FirstName', e.target.value)}                            ></input>
                        </div> </div>



                    </div>
                    <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput99" className="form-label" > تاريخ انتهاء الشهادة  <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable} type='date'
                            className="form-control" name='imageDate'
                             defaultValue={newComemp.imageDate} 
                             onBlur={(e) => handleEdit(props.data.EmployeeID, 'imageDate', e.target.value)}                            />
                        </div></div>
                    </div>

                    <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label className="form-label">الصفة<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <select disabled={props.isEditable} className="default-select form-control" name="DepartmentID" defaultValue={newComemp.DepartmentID}
                                                         onBlur={(e) => handleEdit(props.data.EmployeeID, 'DepartmentID', e.target.value)}                          >

<option  data-display="Select">{props.data.DepartmentName} </option>
                            {
                              Datadepartment.map((row) => (
                                <option key={row.DepartmentID} value={row.DepartmentID}>
                                  {row.DepartmentName}
                                </option>
                              ))}
                          </select>

                        </div>      </div></div>
                        <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1" >
                          <label className="form-label">العنوان <span className="text-danger">*</span></label>

                        </div><div className="col-xl-9 mb-1"  >
                          <textarea disabled={props.isEditable} rows="2" name='Address' className="form-control" defaultValue={newComemp.ImagePath}
                             onBlur={(e) => handleEdit(props.data.EmployeeID, 'ImagePath', e.target.value)}                          ></textarea>
                        </div></div></div>
                    <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput88" className="form-label">رقم الهاتف <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable} type="text" className="form-control" id="exampleFormControlInput88" placeholder="" name='PhoneNumber'
                             defaultValue={newComemp.PhoneNumber} 
                             onBlur={(e) => handleEdit(props.data.EmployeeID, 'PhoneNumber', e.target.value)}                         

                          />
                        </div> </div></div>
                    <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput88" className="form-label">رقم الهاتف 2 <span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable} type="text" className="form-control" id="exampleFormControlInput88" placeholder="" name='PhoneNumber1'
                             defaultValue={newComemp.PhoneNumber1} 
                             onBlur={(e) => handleEdit(props.data.EmployeeID, 'PhoneNumber1', e.target.value)}          >
                             </input>
                        </div>     </div>     </div>
                   
                        <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1">
                          <label htmlFor="exampleFormControlInput88" className="form-label">المرتب<span className="text-danger">*</span></label>
                        </div><div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable} type="text" className="form-control" id="exampleFormControlInput88" placeholder="" name='Salary'
                             defaultValue={newComemp.Salary}
                             onBlur={(e) => handleEdit2(props.data.EmployeeID, 'Salary', e.target.value)}                          />
                    
                        </div>
                      </div></div>
                    <div className="col-xl-4 mb-3">
                      <div className="row">

                        <div className="col-xl-3 mb-1" >
                          <label htmlFor="exampleFormControlInput88" className="form-label">نوع المرتب<span className="text-danger">*</span></label>

                        </div>

                        <div className="col-xl-9 mb-1" >

                          <select disabled={props.isEditable} className="default-select form-control" name="HireType" 
                          defaultValue={newComemp.HireType} 
                          onBlur={(e) => handleEdit(props.data.EmployeeID, 'HireType', e.target.value)}
                                                  
                          >
                            <option data-display="Select">{newComemp.HireType}</option>
                            {
                              radiocheckBox.map((row) => (
                                <option key={row.title} value={row.title}>
                                  {row.title}
                                </option>
                              ))}
                          </select>

                        </div>
                      </div></div>

                    <div className="col-xl-4 mb-3">
                      <div className="row">
                        <div className="col-xl-3 mb-1" >
                          <label htmlFor="exampleFormControlInput88" className="form-label">حالة الموظف<span className="text-danger">*</span></label>

                        </div>

                        <div className="col-xl-9 mb-1" >

                          <select disabled={props.isEditable} className="default-select form-control" name="EmpStatus" defaultValue={newComemp.EmpStatus}                          
                              onBlur={(e) => handleEdit(props.data.EmployeeID, 'EmpStatus', e.target.value)}  
                                                   
                          >
                            <option data-display="Select">{newComemp.StatusName}</option>
                            {
                              employee.map((row) => (
                                <option key={row.StatusTypeID} value={row.StatusTypeID}>
                                  {row.StatusName}
                                </option>
                              ))}
                          </select>

                        </div>
                      </div>
                    </div>
                    </div></div>
                    

                    <div className="col-md-3 mb-3">
                      <div className="row">

                        <div className="col-xl-3 mb-1">

                          <label htmlFor="formFile" className="form-label">تاريخ البدأ</label>
                        </div>
                        <div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable}
                            type="date"
                            className="form-control  " style={{ float: "right" }}
                            id="same-address" Name="HireDate" defaultValue={newComemp.HireDate} 
                            onBlur={(e) => handleEdit(props.data.EmployeeID, 'HireDate', e.target.value)}  
                          ></input>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="row">

                        <div className="col-xl-3 mb-1">
                          <label htmlFor="formFile" className="form-label">تاريخ الانتهاء</label>
                        </div>
                        <div className="col-xl-9 mb-1">
                          <input disabled={props.isEditable}
                            type="date"
                            className="form-control  " style={{ float: "right" }}
                            id="same-address" defaultValue={newComemp.ToHireDate} 
                            onBlur={(e) => handleEdit(props.data.EmployeeID, 'ToHireDate', e.target.value)}                              Name="ToHireDate"></input>

                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 mb-1 "  >
                    <div className="row">

<div className="col-xl-3 mb-1">
                      <p className="mb-1" > من </p>
                      </div>                      <div className="col-xl-8 mb-1">
                      <input disabled={props.isEditable}
                        type="time"
                        className="form-control ms-3 mb-3 "
                        id="same-address"
                        Name="WorkTime"
                         defaultValue={newComemp.WorkTime}
                         onBlur={(e) => handleEdit(props.data.EmployeeID, 'WorkTime', e.target.value)}  
                      /></div></div></div>

<div className="col-xl-3 mb-1 "  >
                    <div className="row">

<div className="col-xl-3 mb-1">
                      <p className="mb-1" >الى </p>
                      </div>
                      <div className="col-xl-8 mb-1">
                      <input disabled={props.isEditable}
                        type="time"
                        className="form-control ms-3 mb-3 "
                        id="same-address"
                        Name="WorkTime"
                         defaultValue={newComemp.ToWorkTime} 
                         onBlur={(e) => handleEdit(props.data.EmployeeID, 'ToWorkTime', e.target.value)}  
                      /></div></div></div>





                  </div>
                  <div className="card-footer">
                    <button  onClick={createEmployee} id="toastr-success-top-right" className="btn btn-primary me-1">حفظ</button>

                    <Link onClick={()=>showInviteModal()} id="toastr-success-top-right" className="btn btn-danger light mx-2">إلغاء</Link>
                  </div>
                 </div></div></div></div>  </div> </Modal>)
})
export default EmpData;