import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

function YourComponent() {
  const [data, setData] = useState([]);
  const [todaydata, settodayData] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // current page state
  const [itemsPerPage] = useState(10); // items per page
  const componentRef = useRef();

  const generatePDF = useReactToPrint({
    content: () => componentRef.current, // this will reference the full table content
    documentTitle: "Userdata",
  });

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
    
    axios.get("https://suhba.server.com.ly/back/api/getTotalAmountbyday")
      .then(response => {
        const totalByDay = response.data.reduce((accumulator, item) => {
          const { deposit_date, deposits, total_amount } = item;
          const isArboon = deposits === 'عربون';
          const existingDay = accumulator.find(entry => entry.Itemdate === deposit_date);
          
          if (existingDay) {
            if (isArboon) {
              existingDay.totalarboon += parseFloat(total_amount);
            } else {
              existingDay.total += parseFloat(total_amount);
            }
          } else {
            accumulator.push({
              Itemdate: deposit_date,
              total: isArboon ? 0 : parseFloat(total_amount),
              totalarboon: isArboon ? parseFloat(total_amount) : 0,
            });
          }
          return accumulator;
          
        }, []);
        
        setData(totalByDay);
        
        // Filter data for today's date
        const todayData = totalByDay.filter(item => item.Itemdate === today);
        settodayData(todayData);
        console.log("Data for today:", todayData);
      })
      .catch(error => {
        console.error("Error fetching outcome data:", error);
      });
  }, []);

  // Logic to handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div className="container-fluid p-0">
      <div className="card-body p-0" ref={componentRef} dir="rtl">
        <div className="row mb-3" style={{ display: 'flex' }}>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <label htmlFor="startMonth" style={{ fontsize: '15px', fontWeight: 'bold' }}> اجمالي الدخل لليوم :</label>
            <div style={{ color: 'blue' }}>
              <strong>{todaydata.length > 0 ? todaydata[0].total : 0}</strong>  
            </div>  
          </div>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <label style={{ fontsize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> اجمالي العرابين لليوم :</label>
            <div style={{ color: 'red' }}>
              <strong>{todaydata.length > 0 ? todaydata[0].totalarboon : 0}</strong>            
            </div> 
          </div>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <label style={{ fontsize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> صافي الدخل لليوم :</label>
            <div style={{ color: 'green' }}>
              <strong>{todaydata.length > 0 ? todaydata[0].total - todaydata[0].totalarboon : 0}</strong>              
            </div> 
          </div>
        </div>

        <div className="table-responsive active-projects dlab-scroll my-2 mx-2">
          <table className="table">
            <thead style={{ position: "sticky", top: 0, zIndex: 1, background: '#ffff' }}>
              <tr>
                <th>التاريخ</th>
                <th>اجمالي  الدخل</th>
                <th>اجمالي العرابين</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{item.Itemdate}</td>
                  <td>{item.total + item.totalarboon}</td>
                  <td>{item.totalarboon}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
   </div>
        

        {/* Pagination Controls */}
        <div className="text-center  ">
 <div className="filter-pagination  mt-3">          <button
            className="btn btn-secondary btn-sm"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            السابق
          </button>
          <span>صفحة {currentPage} من {totalPages}</span>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            التالي
          </button>
        </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary m-2" onClick={generatePDF}>طباعه  </button> 
        </div>
    </div>
  );
}

export default YourComponent;
