import { SVGICON } from "../../constant/theme";
import { useStateContext } from "../../../context/ContextProvider.js";
import { Link } from "react-router-dom";

export const MenuList = [
    // Dashboard
    {
        title: 'الصفحة الرئيسية',
        iconStyle: SVGICON.Home,
        to: '/Dashboard_1',
        permission: 'Dashboard_1'
    },
    {
        title: 'الزبائن',
        iconStyle: <i className="fa-solid fa-users-line"></i>,
        to: '/Customers',
        permission: 'Customers'
    },
    {
        title: 'الطلبيات',
        permission: 'OrdersList',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fa-solid fa-building-circle-exclamation"></i>,
        content: [
            {
                title: 'كل الطلبيات',
                iconStyle: <i className="fa-brands fa-shopify"></i>,
                to: '/OrdersList',
                permission: 'OrdersList'
            },
           
            {
                title: 'ضمانات الطلبيات',
                iconStyle: <i className="fa-brands fa-shopify"></i>,
                to: '/Garantees',
                permission: 'Garantees'
            }
        ]
    },
    {
        title: 'فاتورة مبدئية',
        iconStyle: <i class="fa-solid fa-file-lines "></i>,
        to: '/NotConfirmedOrder',
        permission: 'NotConfirmedOrder'
    },
    {
        title: 'اطباق التذوق',
        iconStyle: <i className="fa-solid fa-city"></i>,
        to: '/Atbak',
        permission: 'Atbak'
    },
    {
        title: 'الصالات',
        iconStyle: <i className="fa-solid fa-city"></i>,
        to: '/WeddingHall',
        permission: 'WeddingHall'
    },
    {
        title: 'الاصناف',
        iconStyle: <i className="fa-solid fa-sitemap"></i>,
        to: '/Al-asnaf',
        permission: 'Al-asnaf'
    },
    {
        title: 'المعدات',
        iconStyle: <i className="fa-solid fa-kitchen-set"></i>,
        to: '/Tools',
        permission: 'Tools'
    },
    {
        title: 'المشتريات',
        iconStyle: <i className="fa-solid fa-cart-shopping"></i>,
        to: '/CompanyPurchases',
        permission: 'CompanyPurchases'
    },
    {
        title: 'الموظف',
        iconStyle:<i className="fa-solid fa-users-line"></i>,
        to: '/Empolyee',
        permission: 'Empolyee'
    },
    {
        title: 'الشركة',
        permission: 'MainCompany',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fa-solid fa-building-circle-exclamation"></i>,
        content: [
            {
                title: 'بيانات الشركة الرئيسية',
                to: '/MainCompany',
                permission: 'MainCompany'
            },
            {
                title: 'إحصائيات الشركة',
                to: '/First',
                permission: 'First'
            },
            {
                title: 'دخل الشركة',
                to: '/Income',
                permission: 'Income'
            },
            {
                title: 'مصروفات الشركة',
                to: '/CompanyExpenses',
                permission: 'CompanyExpenses'
            },
            
           
        ]
    },
    {
        title: 'إدارة المستخدمين',
        classsChange: 'mm-collapse',
        permission: 'ManageUsers',

        iconStyle: <i className="fa-solid fa-users-rectangle"></i>,
        content: [
            {
                title: 'إضافة مستخدم',
                to: '/FormValidation',
                permission: 'FormValidation'
            },
            {
                title: 'بيانات المستخدمين',
                to: '/ManageUsers',
                permission: 'ManageUsers'
            }
        ]
    },
    {
        title: 'إدارة التواريخ',
        permission: 'AllOrderDateLimits',
        classsChange: 'mm-collapse',
        iconStyle: <i class="fa-solid fa-calendar-xmark"></i>,
        content: [
            {
                title: 'كل التواريخ',
                to: '/DateLimitation',
                permission: 'AllOrderDateLimits'
            },
            
        ]
    },
    {
        title: 'إعدادات',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fa-solid fa-gear"></i>,
        content: [
           
            {
                title: 'انواع الوظائف',
                to: '/Departments',
                permission: 'Departments'
            },
            {
                title: 'انواع الظمان',
                to: '/guaranteetype',
                permission: 'guaranteetype'
            },
            {
                title: 'انواع النفقات',
                to: '/ExpenseTypes',
                permission: 'ExpenseTypes'
            },
            {
                title: 'انواع الوحدات',
                to: '/IntTypes',
                permission: 'IntTypes'
            },
            {
                title: 'وحدات المكونات',
                to: '/Ingredients',
                permission: 'Ingredients'
            },
            {
                title: 'حالات الموظف',
               to: '/EmployeeStatus',
            permission: 'EmployeeStatus'
            },
            {
                title: 'انواع التخزبن',
                to: '/StorageType',
                permission: 'StorageType'
            },
            {
                title: 'اسماء المدن',
                to: '/city',
                permission: 'city'
            }
        ]
    }
];
