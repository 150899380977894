import React, { useRef, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnOrders';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row, Dropdown } from 'react-bootstrap';
import InviteCustomer from '../jsx/constant/ToolsReturn';
import InviteCustomer2 from '../jsx/constant/EditOrder';
import { Modal } from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/KitchenReport';
import InviteCustomer3 from '../jsx/constant/RestAmount';
import InviteCustomer5 from '../../src/components/InvoicePrint';
import InviteCustomer31 from '../jsx/constant/ArbonAmount';

import { useEffect, useState } from 'react';
import InviteCustomer4 from '../jsx/constant/DeliveryReport';


export const CompanyExpenses = () => {
  const columns = useMemo(() => COLUMNS, [])
  //const data = useMemo( () => MOCK_DATA, [] )
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [toolitem, settoolitem] = useState([])
  const [data3, setData3] = useState({
    amount: '',
    CustomerName: '',
    deposit_date: '',
    reference_number: '',
    deposits: '',
    reastAmount: '',
  });
  const [currentDate, setCurrentDate] = useState(new Date());

  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

  // Parse the JSON string back to an array
  const userPermissions = JSON.parse(userPermissionsJSON);
  // Function to search for permissions for a specific page
  function getActionsForPage(pageName) {
    // Find the permissions object for the given page
    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
    console.log('actionsForPage', pagePermissions);

    // If permissions for the page are found, return the actions
    if (pagePermissions) {
      return pagePermissions.action;
    } else {
      navigate('/dashboard_1')

      return [];
    }
  }

  // Usage
  const pageName = 'OrdersList';
  const actionsForPage = getActionsForPage(pageName);
  const [ID, setID] = useState(0)
  const [inviteModal1, setInviteModal1] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [inviteModal2, setInviteModal2] = useState(false);
  const [inviteModal5, setInviteModal5] = useState(false);

  const [inviteModal, setInviteModal] = useState(false);
  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, useGlobalFilter, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance
  const { globalFilter, pageIndex } = state
  const navigate = useNavigate();
  const [groupdata, setgroupdata] = useState([])

  const userdata = useRef();
  const invite1 = useRef();
  const cdDataRef = useRef([]);
  const invite5 = useRef();
  const invite31 = useRef();

  const invite = useRef();
  const invite3 = useRef();
  const fetchgroup = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/getorderitemsgrupby').then(({ data }) => {
      setgroupdata(data)
      console.log('groupdata', data);
    })
  }
  console.log('groupdata', groupdata);
  const fetchProducts = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/new_orders').then(({ data }) => { setData(data); console.log('try', data) }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }
  const checkfunc = async () => {
    await axios.get('https://suhba.server.com.ly/back/api/checkfunc').catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }
  console.log('newdata', data);

  const deleteUser = async (e, id) => {
    e.preventDefault();
    swal({
      title: "هل متأكد من الحدف",
      text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {

          axios.delete(`https://suhba.server.com.ly/back/api/expenses/${id}`)

            .then(res => {
              fetchProducts();
              swal({
                title: "تم",
                text: "تم الحدف بنجاح",
                icon: "success",
                timer: 2000,
                button: false
              })
              navigate('#')


            });

        } else {
          swal("تم الغاء عملية الحدف");
        }

      });

  };
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  )
  const [foodIteming, setFoodIteming] = useState([])
  const [totalAmount, setTotalAmount] = useState('')
  const [prepay, setPrepay] = useState('')
  const [paystatus, setPaystatus] = useState('')
  const [reastAmount, setReastAmount] = useState('')
  const [IID, setIID] = useState('')
  const [Amount, setAmount] = useState('')
  const [popupModal, setpopupModal] = useState(false)


  const getfooditeming = async (e, id) => {
    console.log('name', id)
    setFoodIteming([])
    axios.get(`https://suhba.server.com.ly/back/api/getorderitems/${id}`)
      .then(({ data }) => {
        setFoodIteming(data)
        console.log('ibbbd', data)
      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    //  setInviteModal1(true)
    return foodIteming;
  }
  function orderlist(id) {
    console.log('id', id)

    data.map((item) => {
      if (id == item.OrderID) {
        //setTotalAmount(item.TotalAmount)
        //setPrepay(item.Prepay)
        //setPaystatus('مدفوع')
        setReastAmount(item.TotalAmount - item.amounts)
        setIID(id)
        setERrr('')
        setpopupModal(true)
      }
    })
  }
  const handleEdit = () => {
    setERrr('');
    if (Amount > reastAmount) {
      setERrr('القيمة المدخلة اكبر من القيمة المتبقية')
    } else {
      const error = axios.put(`https://suhba.server.com.ly/back/api/orders/${IID}`, {
        ['Amount']: Amount,
        ['Paystatus']: 'تم الدفع',
       // ['Status']: 'تم التسليم',
        ['OrderID']: IID,
        ['UserID']: userid,

      })  .then(({ data }) => {
fetchProducts()       })
      if (!error.response) {

      } else {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
      setpopupModal(false)
     // let temp = data.map((data) => {
     //  if (IID === data.OrderID) {
       //   return { ...data, Status: 'تم التسليم' };
       // }
      //  return data;
    //  });
     // setData(temp);
      navigate('/OrdersList')
    }

  }

  function EditData(id, type) {

    data.map((item) => {
      if (id == item.OrderID) {
        setData2(item)
        console.log('updateTest------------------', item);
        setFoodIteming(item.orderItems)
        settoolitem(item.toolItems)
        if (type === 'fatora') {
          // setInviteModal2(true)
          invite5.current.showInviteModal()
          console.log('vvvvvvvvvvvv', "item.orderItems");

        } else {
          if (type === 'del') {
            // setInviteModal2(true)
            invite1.current.showInviteModal()

          } else {
            if (type === 'kitchen') {
              // tes1();
              // setInviteModal2(true)
              invite.current.showInviteModal()

            } else {
              if (item.Status === 'قيد الانتظار') {
                setViewModal(false)
                setInviteModal(true)
                setInviteModal(true)

              } else {
                setInviteModal(true)
                setViewModal(true)
              }
            }
          }
        }
      }
    })


  }
  const [report, setreport] = useState({})
  //--------------------new-----------------------
  const EditData556 = async (e, id) => {
    console.log('idedit', id);
    const response = await axios.get(`https://suhba.server.com.ly/back/api/getdipositfullpayed/${id}`);
    const depositData = response.data.Deposit;
    const ArboonData = response.data.arboon;
    console.log('vvvvvvdepositDatavvvvvv', depositData);

    let singleamount = 0;
    if (depositData.length > 0) {
      setData3(depositData);

      // Map through depositData to create new objects
      const mappedData = depositData.map(deposit => {
        // Find the corresponding item in data based on OrderID
        const correspondingItem = data.find(item => item.OrderID === deposit.OrderID);
        singleamount += parseInt(deposit.amount);
        console.log('singleamount', singleamount)
        const reastAmount = correspondingItem ? (correspondingItem.TotalAmount - ArboonData.amount - singleamount) : null;

        // Create a new object combining data from both sources
        return {
          amount: deposit.amount,
          CustomerName: correspondingItem ? correspondingItem.CustomerName : null,
          deposit_date: deposit.deposit_date,
          reference_number: deposit.reference_number,
          deposits: deposit.deposits,
          reastAmount: reastAmount
        };
      });

      console.log('mappedData', mappedData);

      // Update report state with mapped data
      setreport(mappedData);

      invite3.current.showInviteModal()
    } else {
      swal(" لم يتم الدفع بعد");

    }









    //setID(id); setInviteModal(true)
    //getfooditeming(e, id)              swal(" لم يتم الدفع بعد");


    // invite3.current.generatePDF();
  }


  //-----------------------new-------------------
  const EditData55 = async (e, id) => {
    console.log('idedit', id);

    e.preventDefault();
    const response = await axios.get(`https://suhba.server.com.ly/back/api/getdiposit/${id}`);
    const depositData = response.data.Deposit;
    if (depositData) {
      setData3(depositData)
      data.map((item) => {
        if (id == item.OrderID) {
          const newObj = {
            amount: depositData.amount,
            CustomerName: item.CustomerName,
            deposit_date: depositData.deposit_date,
            reference_number: depositData.reference_number,
            deposits: depositData.deposits,
            reastAmount: (item.TotalAmount - item.Prepay)
          };

          console.log('data3', newObj)

          setreport(newObj)
        }

      })

      invite31.current.showInviteModal()

    } else {
      swal(" هده الطلبية ملغية");

    }




    //setID(id); setInviteModal(true)
    //getfooditeming(e, id)

    // invite3.current.generatePDF();
  }
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
  const userid = userData.id;
  const EditData1 = async (e, id) => {
    console.log('id', id)

    data.map((item) => {
      if (id == item.OrderID) {
        setData2(item)
        console.log('updateTest', item.orderItems);
        settoolitem(item.toolItems)

      }
    })
    console.log('updateTest', data2);



    setInviteModal1(true)

  };

  const show = (value) => {
    //setFoodIteming('')
    setInviteModal(value);
  }
  const show1 = (value) => {
    //setFoodIteming('')
    setInviteModal1(value);
  }
  const handleSelect = (eventKey, rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].Status = eventKey;
    setSelectedItem(eventKey)
    console.log(eventKey)

    setData(updatedData); console.log(rowIndex);
  };
  const [selectedItem, setSelectedItem] = useState();
  const [Err, setERrr] = useState('');
  const handleActioncancelorder  = async (id, field, value) => {
     swal({
      title: "هل متأكد من الالغاء",
      text: " في حال الغاء الطلبية   لايمكنك استعادتها ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(willDelete => {
      if (willDelete) {
        const currentPageIndex = state.pageIndex; // Get the current page index
  
        let temp = data.map((data) => {
          if (id === data.OrderID) {
    
            return { ...data, [field]: value };
          }
          return data;
    
        });
        setData(temp)
        console.log('temp',userid)
             const error = axios.put(`https://suhba.server.com.ly/back/api/storestate/${id}`, {
          [field]: value,
          ["UserID"]: userid,
          ["OrderID"]: id,
    
        })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          }); gotoPage(currentPageIndex)
      
    
      } else {
        swal("تم التراجع عن عملية الالغاء");
      }
    
    });
  
  };
  const handleAction = async (id, field, value) => {
    console.log('id', id)
 
      const currentPageIndex = state.pageIndex; // Get the current page index

      let temp = data.map((data) => {
        if (id === data.OrderID) {

          return { ...data, [field]: value };
        }
        return data;

      });
      setData(temp)
      console.log('temp',userid)
      const formData = new FormData();
      formData.append('_method', 'PATCH')
      formData.append('OrderID', id);
      formData.append('Status', value);
      formData.append('UserID', userid);

      const error = await axios.put(`https://suhba.server.com.ly/back/api/storestate/${id}`, {
        [field]: value,
        ["UserID"]: userid,
        ["OrderID"]: id,

      })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        }); gotoPage(currentPageIndex)
    
  }
  console.log(selectedItem)
  useEffect(() => {
    fetchProducts();
    fetchgroup()
    checkfunc()
  }, [])
  const [cdData, setcdData] = useState([])
  const test = () => {
    // Initialize an array to store the combined data

    // Loop through each item in groupData
    const combinedData = [];
    groupdata.forEach((groupItem, index) => {
      // Extract name and quantity from groupItem
      const name = groupItem.Name;
      const quantity = groupItem.quantity_count;
      const details = [];

      // Find the corresponding data item in the data array
      const dataItem = data.find(item => {
        return item.orderItems.some(orderItem => orderItem.Name === groupItem.Name);

      });

      if (dataItem) {
        // Extract details from the data item
        const { CustomerName } = dataItem;

        // Filter orderItems to get matching details
        const matchingOrderItems = dataItem.orderItems.filter(orderItem => orderItem.Name === groupItem.Name);
        console.log('matchingOrderItems', matchingOrderItems)

        // Push details into the array
        matchingOrderItems.forEach(orderItem => {
          details.push({
            CustomerName: CustomerName,
            quantity: orderItem.Quantity
          });
        });

        // Push combined data into the array
        combinedData.push({ name, quantity, details });
      }

    });
    setcdData(combinedData)
    console.log('dataItem', cdData)
  }
  const formatDate = (date) => {

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;

  };
  const tes1 = () => {
    // Initialize an array to store the combined data
    const combinedData = [];

    // Loop through each item in groupData
    groupdata.forEach((groupItem, index) => {
      // Extract name and quantity from groupItem
      const filteredArray = data.filter(item => item.Status === 'قيد العمل' && item.DeliveryDate === formatDate(currentDate));
      if (filteredArray.length > 0) {
        const name = groupItem.Name
        let quantity = 0;
        let deldate = 0;
        let deltime = 0;

        const details = []
        // Find the corresponding data item in the data array
        // const dataItem = data.find(item => item.orderItems.some(orderItem => orderItem.Name === groupItem.Name));   
        const dataItem = filteredArray.map((item, ind) => {
          if (item.orderItems.Name === groupItem.Name);
          if (item) {
            // Extract details from the data item
            item.orderItems.map(ss => {
              if (ss.Name === groupItem.Name)

                if (ss) {
                  quantity += ss.Quantity;
                  deldate = item.DeliveryDate
                  details.push({
                    CustomerName: item.CustomerName,
                    quantity: ss.Quantity,
                    deltime: item.DeliveryTime
                  });
                }
            });


          }
        })
        if (details.length > 0) {
          combinedData.push({ name, deldate, quantity, details });
        }
        // combinedData.push({ name, quantity, details });    

        // If the data item is found, create details array



      }
    })

    const filteredArray = data.filter(item => item.Status === 'قيد العمل');
    console.log('filteredArray', filteredArray)
    if (filteredArray.length > 0) {
      setcdData(combinedData)
      cdDataRef.current = combinedData;
    } else {
      setcdData([])
      cdDataRef.current = [];

    }

    invite.current.showInviteModal()

    console.log('dataItem', cdData)
  }


  return (
    <>
      <div className='container-fluid'>
        <Row>
          <Col lg="12">
            <div className="card">

              <div className="card-header">
                <h4 className="heading mb-0">الطلبيات</h4>
                {actionsForPage.includes('export') && (
  <Link to={"#"} className="btn btn-secondary btn-sm ms-1" data-bs-toggle="offcanvas"
                  onClick={tes1}  > <i class="fa-solid fa-print ms-1"> طباعه تقرير الطلبيات للمطبخ</i>
                </Link>
 )}
              </div>



              <div className="table-responsive">
                <table {...getTableProps()} className="table dataTable display scrol table-hover">
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          // Check if the column accessor is 'age' and render accordingly
                          column.id !== 'OrderID' ? (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                              {column.canFilter ? column.render('Filter') : null}
                            </th>
                          ) : null
                        ))}
                        <th style={{ paddingBottom: "4.5rem" }}>الباقي</th>
                        <th style={{ paddingBottom: "4.5rem" }}>العربون </th>
                        <th style={{ paddingBottom: "4.5rem" }}>الاستلام </th>
                        <th style={{ paddingBottom: "4.5rem" }}>الفاتورة </th>

                        <th style={{ paddingBottom: "4.5rem" }}>الاجراءات</th>

                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="">
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={index}>
                          {row.cells.map((cell) => {
                            const cellProps = cell.getCellProps();
                            const cellValue = cell.render('Cell');
                            const isStatusColumn = cell.column.id === 'Status';
                            const isidColumn = cell.column.id === 'OrderID';

                            // Check if the column is the 'status' column
                            if (isStatusColumn) {
                              const statusValue = row.original.Status;

                              // Add a class based on the status value
                              const statusClass =
                                statusValue === 'جاهزة' ? 'Complete' :
                                  statusValue === 'تم التسليم' ? 'In Progress' :
                                    statusValue === 'ملغية' ? 'Medium' :
                                      statusValue === 'قيد الانتظار' ? 'Pending' :
                                        'Testing';

                              return (
                                <td {...cellProps}>
                                  <Dropdown className="task-dropdown-2" >
                                    <div>
                                      <Dropdown.Toggle style={{ width: "100px" }} className={statusClass} id="myElementId" as="div">
                                        {statusValue}
                                      </Dropdown.Toggle>
                                    </div>
                                    {/* Render Dropdown.Menu only if statusValue is not 'تم التسليم' */}
                                    {statusValue !== 'تم التسليم' && statusValue !== 'ملغية' && (
                                      <Dropdown.Menu className='task-drop-menu'>
                                        <Dropdown.Item eventKey="جاهزة" onClick={() => handleAction(row.original.OrderID, 'Status', 'جاهزة')}>
                                          جاهزة
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="قيد الانتظار" onClick={() => handleAction(row.original.OrderID, 'Status', 'قيد الانتظار')}>
                                          قيد الانتظار
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="تم التسليم" onClick={() => handleAction(row.original.OrderID, 'Status', 'تم التسليم')}>
                                          تم التسليم
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="ملغية" onClick={() => handleActioncancelorder(row.original.OrderID, 'Status', 'ملغية')}>
                                          ملغية
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="قيد العمل" onClick={() => handleAction(row.original.OrderID, 'Status', 'قيد العمل')}>
                                          قيد العمل
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    )}
                                  </Dropdown>
                                </td>
                              );
                            }


                            if (!isidColumn) {
                              return <td {...cellProps}>{cellValue}</td>;
                            }
                          })}


                          <td>
                            {row.original.TotalAmount - row.original.amounts}
                          </td>

                          <td>                       {actionsForPage.includes('export') && (
<Link dir="rtl" className=""
                            to={"#"}
                            onClick={(e) => EditData55(e, row.original.OrderID)}
                          > <i class="fa-solid fa-file-lines fa-2x"></i>
                          </Link>
                          )}
                          </td>
                          <td>   {actionsForPage.includes('export') && (<Link dir="rtl" className=""
                            to={"#"}
                            onClick={(e) => EditData556(e, row.original.OrderID)}
                          > <i class="fa-solid fa-file-lines fa-2x"></i>
                          </Link>
 )}
                          </td>
                          <td>  {actionsForPage.includes('export') && ( <Link dir="rtl" className=""
                            to={"#"}
                            onClick={(e) => EditData(row.original.OrderID, "fatora")}
                          > <i class="fa-solid fa-file-lines fa-2x"></i>
                          </Link> )}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                className="light sharp i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu dir="rtl" style={{ minWidth: "8rem" }} className="dropdown-menu-right">
                              {actionsForPage.includes('edit') && (

                                <Dropdown.Item onClick={(e) => EditData(row.original.OrderID)}
                                >

                                  <Link to dir="rtl" className=""
                                  >تعديل
                                  </Link>
                                </Dropdown.Item>
 )}
                             {actionsForPage.includes('edit') && (

                                <Dropdown.Item onClick={(e) => EditData1(e, row.original.OrderID)}>
                                  <Link to dir="rtl" className=""

                                  >إعداة معدات
                                  </Link>
                                </Dropdown.Item>
 )}
                             {actionsForPage.includes('edit') && (

                                <Dropdown.Item onClick={(e) => orderlist(row.original.OrderID)}>
                                  <Link to dir="rtl" className=""
                                  >   دفع قيمة                                </Link>
                                </Dropdown.Item>

)}
                            {actionsForPage.includes('export') && (

                                <Dropdown.Item onClick={(e) => EditData(row.original.OrderID, "del")}
                                >
                                  <Link to dir="rtl" className=""
                                  >  تقرير لتوصيل                                </Link>
                                </Dropdown.Item> )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>




                </table>



                <div className="d-flex justify-content-between mx-2">
                  <span>
                    الصفحه{' '}
                    <strong>
                      {pageIndex + 1} من {pageOptions.length}
                    </strong>{''}
                  </span>
                  <span className="table-index ">
                    ادهب الى : {' '}
                    <input type="number"
                      className="ml-2"
                      defaultValue={pageIndex + 1}
                      onChange={e => {
                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(pageNumber)
                      }}
                    />
                  </span>
                </div>
                <div className="text-center mb-3">
                  <div className="filter-pagination  mt-3">
                    <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {/* {'<'} */}
                      <i className='fa-solid fa-angle-right' />
                    </button>

                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                      السابق
                    </button>
                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                      التالي
                    </button>
                    <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {/* {'>'} */}
                      <i className='fa-solid fa-angle-left' />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </Col>
        </Row>
      </div>
      <Modal className="fade bd-example-modal-sm" size="sm" id="exampleModal1" show={popupModal} centered>
        <div className="modal-content">
          <div className="modal-body">
            <div className="col-sm-12">
              <div>
                <label className="form-label">القيمة المبقية : {reastAmount}</label>
              </div>
              <div>
                <label className="form-label">ادخل باقي القيمة<span className="text-danger">*</span></label>
              </div>
              <input type="text"
                className="form-control"
                defaultValue=''

                //placeholder="ادخل قيم عددية صحيحه  فقط"
                required
                id="inputcity"
                name="city"
                onChange={(e) => setAmount(e.target.value)} ></input>

            </div>
            <div style={{ color: "red" }}>{Err}</div>
            <div className="card-footer" >

              <button type="button" className="btn btn-primary light mx-1" onClick={handleEdit}>اضف</button>

              <button type="button" className="btn btn-danger light" onClick={() => setpopupModal(false)}>اغلاق</button>
            </div>
          </div>
        </div>

      </Modal>


      <InviteCustomer2
        data={data2}
        data2={foodIteming}
        data3={toolitem}

        parentFunction={fetchProducts}
        inviteModal={inviteModal}
        viewModal={viewModal}
        show={show} />
      <InviteCustomer1
        ref={invite}
        newdata={cdDataRef.current} // Pass cdDataRef.current as a prop        data={data2}
        data2={foodIteming}
        data3={toolitem}

        parentFunction={fetchProducts}
        inviteModal={inviteModal2}
        viewModal={viewModal}
        show={show} />
      <InviteCustomer4
        ref={invite1}

        data={data2}
        data2={foodIteming}
        data3={toolitem}

        parentFunction={fetchProducts}
        inviteModal={inviteModal2}
        viewModal={viewModal}
        show={show} />
      <InviteCustomer5
        ref={invite5}

        data={data2}
        data2={foodIteming}
        data3={toolitem}

        parentFunction={fetchProducts}
        inviteModal={inviteModal5}
        viewModal={viewModal}
        show={show} />

      <InviteCustomer
        data={toolitem}
        data2={data2}
        parentFunction={fetchProducts}
        inviteModal={inviteModal1}
        show={show1} />
      <InviteCustomer3
        ref={invite3} data={report}
      />
      <InviteCustomer31
        ref={invite31} data={report}
      />
    </>


  );
}
export default CompanyExpenses;