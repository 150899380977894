import React, { Fragment, useState, forwardRef, useImperativeHandle, useEffect,useRef } from 'react';
import swal from 'sweetalert';
import axios from "axios";
 import { Badge,Accordion } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab, Nav, ListGroup } from "react-bootstrap";
 import { Modal } from 'react-bootstrap';
 import html2canvas from 'html2canvas';
import logo from '../images/tabak.jpg'
import logo1 from '../jsx/components/Reports/food.jpg';

const Orders = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const contentRef = useRef(null);

    const handleDownloadImage = () => {
        if (contentRef.current) {
            html2canvas(contentRef.current, { useCORS: true }).then((canvas) => {
                const link = document.createElement('a');
                link.download = 'output-image.png';
                link.href = canvas.toDataURL('image/png');
                link.click();
            });
        }
    };
  const [shownext, setshownext] = useState(false);
  const [inviteModal, setInviteModal] = useState("hidden");
  const [inviteModal1, setInviteModal1] = useState(true);
  const [listItem, setlistItem] = React.useState([])
  const [listItem1, setlistItem1] = useState([])
  const [popupModal, setpopupModal] = useState(false);

  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setInviteModal("hidden")
      setInviteModal1(true)

    }
  }));
  const location = useLocation();
  const location2 = useLocation();
  const [itemName, setItemName] = useState('');

  const [cName, setCName] = useState('');
  const [last, setlast] = useState(0)
   
   
 
  const [data, setData] = useState([])
  const [qun, setQun] = useState(0)
  const [onoff, setonoff] = useState(true)
  const [total, setTotal] = useState(0)
  const [atbak, setAtbak] = useState([])


  const showInviteModal1 = () => {

    setInviteModal1(false)
    setInviteModal("Block")
  }
  const notifyInfo = () => {
    swal({
      title: "هل متاكد من الغاء هده العملية",
      text:
        "بمجرد الضفظ على نعم سيتم اعادة توجيهك الى الصفحه الرئيسية",
      icon: "warning",
      buttons: true,
      showCancelButton: true,
      dangerMode: true,
      confirmButtonAriaLabel: 'نعم',
      cancelButtonText: "العودة",
    }).then((willDelete) => {
      if (willDelete) {
        navigate('/dashboard_1')
      } else {
        setInviteModal(false)
      }
    })

  }

  const handleDelete = (index) => {
    // Create a copy of the list
    const updatedList = [...listItem];

    // Remove the item at the specified index
    updatedList.splice(index, 1);
    const totalAA = updatedList.reduce((total, item) => total + item.aa, 0);
    setTotal(totalAA);
    // Update the state with the new list
    setlistItem(updatedList);
     
    if (updatedList.length === 0) {
      

      setQun(0)
     }
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  const [catigorydata, setCatigorydata] = useState([])
  const input = document.querySelector('input[name="city"]');
  if (input) {
    input.addEventListener('invalid', function (event) {
      if (event.target.validity.valueMissing) {
        event.target.setCustomValidity('الرجاء ادخال  القيمة');
      }
    })

    input.addEventListener('change', function (event) {
      event.target.setCustomValidity('');
    })
  }
  
  const getFoodCatigory = () => {
    axios.get('https://suhba.server.com.ly/back/api/getFoodCatigory')
      .then(({ data }) => {
        setCatigorydata(data.FoodCategory)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  } 
 

  const fetchProducts = async () => {
    //await axios.get('https://suhba.server.com.ly/back/api/order_items').then(({ data }) => { setData(data) })
    axios.get('https://suhba.server.com.ly/back/api/order_items')
    .then(response => {
      setlistItem1(response.data.FoodItem)
    const itemMap = {};
    response.data.FoodItem.forEach(item => {
      if (itemMap[item.ItemID]) {
        itemMap[item.ItemID].totalQuantity += item.Quantity;
        itemMap[item.ItemID].totalprice += item.ItemPrice ? item.ItemPrice * item.Quantity : 0;
      } else {
        itemMap[item.ItemID] = {
          ItemID: item.ItemID,
          Name: item.Name,
          CategoryID:item.CategoryID,
          totalQuantity: item.Quantity,
          ItemPrice: item.ItemPrice,
          totalprice: item.ItemPrice ? item.ItemPrice * item.Quantity : 0
        };
      }
    });
    const totalQuantityByItem = Object.values(itemMap);
    totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
    setData(totalQuantityByItem);
  })
  }

  const handleSearch = () => {
    setonoff(false)
    console.log('تتت',itemName);
    if(itemName != ''){
const filteredData = listItem1.filter(item => {
 		  
		  // Check if the item name includes the entered item name
		 // const itemNameMatch = item.Name.toLowerCase().includes(itemName.toLowerCase());
		 const itemNameMatch = item.Name.toString().includes(itemName.toString());
		  // Check if month and year filters are provided
 	  
		  // Return true if all conditions match
		  return  itemNameMatch;
		});
    const itemMap = {};

    filteredData.forEach(item => {
      if (itemMap[item.ItemID]) {
        itemMap[item.ItemID].totalQuantity += item.Quantity;
        itemMap[item.ItemID].totalprice += item.ItemPrice ? item.ItemPrice * item.Quantity : 0;
      } else {
        itemMap[item.ItemID] = {
          ItemID: item.ItemID,
          Name: item.Name,
          CategoryID:item.CategoryID,
          totalQuantity: item.Quantity,
          ItemPrice: item.ItemPrice,
          totalprice: item.ItemPrice ? item.ItemPrice * item.Quantity : 0
        };
      }
    });

    const totalQuantityByItem = Object.values(itemMap);
    totalQuantityByItem.sort((a, b) => b.totalQuantity - a.totalQuantity);
     console.log("totalQuantityByItem",totalQuantityByItem)
     setFilteredData(totalQuantityByItem);
  }

     
  };
  const [activeTab, setActiveTab] = useState();
  const [filteredData, setFilteredData] = useState([]);

  console.log('filteredDatafilteredData', filteredData)

  const handleTabSelect = (key) => {
    setActiveTab(key);
    // setFilteredData([])
    // const acc =[]
    const filtered = data.reduce((acc, item) => {

      if (item.CategoryID == key) {
        acc.push(item);
      }
      return acc;
    }, []);

    setFilteredData(filtered);
    console.log('filtered', filtered)
  };

   const [currentDate, setCurrentDate] = useState(new Date());
 
  useEffect(() => {
  
 
    fetchProducts();
 
     getFoodCatigory();
    //getLastCustomer();
    //if (receivedItemId != null) {
      //setlast(receivedItemId)
      //setlistItem(receivedDataArray)
      //setInviteModal1(false)
   // }
    const filtered = data.filter((item) => item.CategoryID == activeTab);
    setFilteredData(filtered);
  }, []);
  console.log('activeTab', activeTab)

  console.log('filteredData',data)
   
 
    
  
  const [errors, setErrors] = useState('')


  const listClickHandler1 = (id, jop, itemid, TItemID, AtbakID,CategoryID) => {
    console.log('setItemPrice',jop)
    // setFoodCategoryID(CategoryID)
    // setItemPrice(jop)
    // setItemID(itemid)
    // setName(id)
    let updatedList;

       // Convert qun to a number, defaulting to 0 if conversion fails
        const itemExists = listItem.some(
          (item) => item.ItemName === id && item.ItemID === itemid
        );

        if (itemExists) {
           swal('هدا الصنف موجود في الطبق')
        } else {
            const qunAsNumber = 1

            const calc = 0;
           let cal = jop * qunAsNumber;
          updatedList = [
            ...listItem,
            { ItemName: id, ItemPrice: jop, aa: cal, Quantity: qunAsNumber, ItemID: itemid, FoodCategoryID:CategoryID},
          ];
       

        setlistItem(updatedList);
        const totalAA = updatedList.reduce((total, item) => total + item.aa, 0);
        setTotal(totalAA);
     //   setQun('');
         setErrors('')
 }
       
       
  }
 
  
  const [Name, setName] = useState('');
  const [ItemPrice, setItemPrice] = useState('');
  const [ItemID, setItemID] = useState('');
  const [TItemID, setTItemID] = useState('');
  const [TName, setTName] = useState('');
  const [TabakName, setTabakName] = useState('');
  const [AtbakID, setAtbakID] = useState('');
  const [FoodCategoryID, setFoodCategoryID] = useState('');


  console.log('listItemlistItemlistItemlistItem',listItem)
  const queryString = new URLSearchParams({
    data: JSON.stringify(listItem),
    id: last.toString(),
    cname:cName,
    //total:total,

  }).toString();
    console.log('queryString', queryString)

  //const searchParams = new URLSearchParams(location.search);
  //const receivedDataArray = JSON.parse(searchParams.get('data')) || [];
  //const receivedItemId = searchParams.get('id') || '';
  //console.log('receivedDataArray', receivedDataArray)
 
  
  

 
  const handleClearSearch = () => {
    setonoff(true)

    setItemName('');
    fetchProducts();
    const filtered = data.filter((item) => item.CategoryID == activeTab);
    setFilteredData(filtered);
  };
  return (

    <>
      <div className="" id="exampleModal1" style={{ visibility: { inviteModal } }} centered>

        <div className="container-fluid " style={{padding:'0.500rem'}}>
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-0 h-auto">
                <div className="card-body p-0">
                  <div className="row gx-0">
                    <div className="col-xl-12 col-xxl-12 col-lg-12">
                    <div className="email-left-box dlab-scroll  pt-3 ps-2">
                  
                      <Accordion defaultActiveKey="1" className="accordion accordion-primary">
      <Accordion.Item eventKey="0" className="accordion-item">
        <Accordion.Header className="accordion-header rounded-lg">
            <div className='d-flex'>
                      <Badge as="a" href="" bg="primary badge-circle">
                    <i class="fa-solid fa-plate-wheat m-2"></i> {listItem.length}
                   </Badge>
                  <span className="badge badge-primary badge-pill">

                  </span>     
                        <h4 className="heading m-2">محتويات الطبق</h4>
                   </div>      </Accordion.Header>
        <Accordion.Body className="accordion-body">
          <div className="card-body p-0">
            <div className="table-responsive active-projects dlab-scroll hover"style={{                    height:'200px',
}}  >
              <table id="projects-tbl4" className="table">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >                  <tr>
                    <th>اسم الصنف</th>
                     <th>السعر</th>
                    <th>الغاء</th>
                  </tr>
                </thead>
                 <tbody>
  {listItem.length > 0 ? (
    listItem.map((item, index) => (
      <tr key={index}>
        <td>{item.ItemName}</td>
         <td>{item.aa}د</td>
        <td>
          <Link onClick={() => handleDelete(index)}>
            <i className="fas fa-trash-alt"></i>
          </Link>
        </td>
      </tr>
    ))
  ) : (
    <center>
        الصحن فارغة
    </center>
  )}
</tbody>

               </table>
            </div>

            <table className="table table-clear">
              <tbody>
                <tr>
                  <td className="left">
                    <strong>الحساب الكلي</strong>
                  </td>
                  <td className="right">{total}</td>
                  <td>
                    <Link to='#' className="btn btn-primary"
                    onClick={e => {
                                              setshownext(true);
                                              //handleSearch();
                                            }}>
                      التالي
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                  <center>  <div className="col-md-8 pb-3" style={{ display: 'flex' }}>
                  <label htmlFor="itemName" className="form-label ms-2 mt-3" style={{whitespace: "nowrap"}}>بحث </label>
                    <input style={{  height: "2rem" }} 
                                            type="text" 
                                               
                                               name="itemName"
                                            className="form-control mt-3" 
                                            value={itemName} 
                                            onChange={e => {
                                              setItemName(e.target.value);
                                              //handleSearch();
                                            }}
                                            
                                            />
                          
 				 
             
                <div className="col-md-3 ">
                  <button className="btn btn-primary btn-sm mt-3 me-1" hidden={!onoff} onClick={handleSearch}>ابحت</button>
                  <button className="btn btn-secondary btn-sm  mt-3 me-1" hidden={onoff} onClick={handleClearSearch}> الغاء</button>

                 </div></div> </center>
                      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
                        <div role="toolbar" className="toolbar ms-1 ms-sm-0">
                          <div className="saprat">
                            <div className="d-flex align-items-center"style={{ display: 'block', overflowY: 'auto', whiteSpace: 'nowrap', scrollbarWidth: 'thin' }}>

                            <Nav as="ul" className="nav nav-pills" id="pills-tab" role="tablist" style={{ display: 'block', overflowY: 'auto', whiteSpace: 'nowrap', scrollbarWidth: 'thin' }}>                              
                              {/* <Nav.Item as="li" className="nav-item btn-group" role="presentation">
                                  <Nav.Link as="button" eventKey={'Tabak'} type="button" className="btn effect mx-2 nav-link" id="pills-home-tab" >
                                    <i class="fa-solid fa-plate-wheat"></i>                                                                                           اطباق
                                  </Nav.Link>
                                </Nav.Item>*/} 
                                {
                                  catigorydata.length > 0 && (
                                    catigorydata.map((row) => (

                                      <Nav.Item as="li" className="nav-item btn-group" role="presentation" key={row.FoodCategoryID} >
                                        <Nav.Link as="button" eventKey={` ${row.FoodCategoryID}`} type="button" className="btn effect mx-2 nav-link" id="pills-home-tab" >
                                          <i className={`fa-solid ${row.Description} ms-2`}></i>
                                          {row.FoodName}
                                        </Nav.Link>
                                      </Nav.Item>
                                    ))
                                  )
                                }
                                  
                              </Nav>

                            </div>

                          </div>
                        </div>
                        {/** Single Message */}


                        <Tab.Content>
                        
                      
                          <Tab.Pane eventKey={activeTab}>
                            <div className="row mt-2">

                              {
                                filteredData.length > 0 && (
                                  filteredData.map((row) => (


                                    <div className="col-xl-2 col-xxl-3 col-md-4 col-sm-6" key={row.ItemID}>
                                      <div className="card">
                                        <Link to className="btn  mx-2 effect nav-link nav-link"
 onClick={() => listClickHandler1(row.Name,  row.ItemPrice, row.ItemID ,row.CategoryID)}>
                                          <div className="col-lg-12">

                                            <div className="col-sm-12">
                                              <div className="new-arrival-content text-center ">
                                                <h5>
                                                  <span className="" >{row.Name}</span> </h5>
                                              </div>
                                            </div>

                                            <div className="col-sm-12">

                                              <div className="new-arrival-content text-center ">

                                                <h4> <span className="price" > {row.ItemPrice}د </span> </h4>
                                              </div> </div>






                                          </div>
                                        </Link>
                                      </div>

                                    </div>
                                  ))
                                )
                              }
                            </div>

                          </Tab.Pane>
                        
                        </Tab.Content>

                      </Tab.Container>
                    </div>
                  </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

 <Modal className="modal fade" id="exampleModal1" show={shownext} centered>
        <div className="modal-content">
        <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">  محتويات الصحن  </h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={e => {
                                              setshownext(false);
                                              //handleSearch();
                                            }}></button>
                    </div>
                    <div ref={contentRef} className="modal-body">     
                               <div className="new-arrivals-img-contnent">
                               <div className="col-xl-12">
                <center><img src={logo1} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info mt-2">
                تم انشاء هدا الصحن بتاريخ :{formatDate(currentDate)}
                  </div>
              </div>
                                                          
    
           
                                                               </div>
        <div className="table-responsive active-projects dlab-scroll hover border mt-3">
        <table id="projects-tbl4" className="table">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >                  <tr>
                    <th>اسم الصنف</th>
                     <th>السعر</th>
                   </tr>
                </thead>
                 <tbody>
  {listItem.length > 0 ? (
    listItem.map((item, index) => (
      <tr key={index}>
        <td>{item.ItemName}</td>
         <td>{item.aa}د</td>
         
      </tr>
    ))
  ) : (
    <center>
        الصحن فارغة
    </center>
  )}
</tbody>

               </table>    
                                                            
               </div>  
               </div>  
               <button className="btn btn-success ة-3 " onClick={handleDownloadImage}>تحميل الصورة</button>      </div>

      </Modal>
   
    </>
  );
});

export default Orders;
