import React, { useImperativeHandle } from "react";
import    { useState ,useEffect,useRef,forwardRef} from "react";
import qrcode from "../../src/images/qrcode.png";
import { SVGICON } from "../jsx/constant/theme";
import { useLocation,useNavigate } from 'react-router-dom';
import logo from '../jsx/components/Reports/food.jpg';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Invoice = React.forwardRef((props, ref) => {
  const location = useLocation();const componentRef = useRef();
  const componentRef1 = useRef()
  const generatePDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Userdata",
    onAfterPrint: () => setInviteModal(false)
  });
  ;
  const generatePDF1 = useReactToPrint({
    content: () => componentRef1.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF")
  });
  const searchParams = new URLSearchParams(location.search);

  const receivedDataArray = JSON.parse(searchParams.get('data')) || [];
  const receivedItemId = searchParams.get('id') || '';
  const DiscountAmount = searchParams.get('DiscountAmount') || '';
  const CustomerName = searchParams.get('name') || '';
  const lastdip = searchParams.get('TotalAmount') || '';
  const lastorder = searchParams.get('lastorder') || '';

  const [data3, setData3] = useState({});
  const [toolItems, settoolItems] = useState([]);

  const [orderItems, setOrderItems] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [Discount1, setDiscount1] = useState(0);
  const [report, setreport] = useState({})
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
    const [order, setOrder] = useState({
      //  OrderID:'',
      CustomerID: '',
      OrderDate: '',
      TotalAmount: '',
      DiscountAmount: '',
      Status: '',
      UserID: userid,
      CustomerName: '',
      DeliveryPersonID: '',
      DeliveryDate: '',
      DeliveryTime: '',
      DelPrice: '',
      Address: '',
      GuaranteeTypesID: '',
      Prepay:'',
    });
  let sum = 0
  useEffect(() => {
    setOrderItems(props.data2);
    //settoolItems(props.data3)
    setOrder(props.data)
    settoolItems(props.data3)

  // cal();
   // EditData55(lastorder)
   // const totalAmounts = orderItems.map(item => item.TotalAmount);
    //console.log("totalAmounts", totalAmounts)
  
        
  
    setTotalAmount(order.TotalAmount);
 
    setDiscount1(order.DiscountAmount);
    console.log(Discount1)
  }, [props.data, setOrder])
  console.log("newOrderItems", orderItems)
  
   // const intervalId = setInterval(() => {
    //   setOrderDate(new Date());
   //  }, 1000); // Update every 1000 milliseconds (1 second)
 
     // Cleanup function to clear the interval when the component unmounts
   //  return () => clearInterval(intervalId);
   const cal = () => {
    const totalAmounts = orderItems.map(item => item.TotalAmount);
    console.log("totalAmounts", totalAmounts)
  
        
  
    setTotalAmount(totalAmounts[0]);
    console.log(TotalAmount)

    setDiscount1(DiscountAmount);
    console.log(Discount1)

    //setDiscount((totalItemPrice-DiscountAmount)) ;
    //console.log(Discount)

  };
   
  const EditData55 = async (id) => {
    console.log('idedit', id);

     const response = await axios.get(`https://suhba.server.com.ly/back/api/getdiposit/${lastorder}`);
    const depositData = response.data.Deposit;
       setData3(depositData)
    

  
      
    //setID(id); setInviteModal(true)
    //getfooditeming(e, id)
     
        const newObj = {
          amount: depositData.amount,
          CustomerName: CustomerName, 
          deposit_date: depositData.deposit_date, 
          reference_number: depositData.reference_number,
          deposits: depositData.deposits,
          reastAmount: (orderItems.TotalAmount - depositData.amount),
        };
   
    console.log('data3', newObj)

      setreport(newObj)
 
 
   // invite3.current.showInviteModal()
   // invite3.current.generatePDF();
  }
  const [inviteModal, setInviteModal] = useState(false);
  useImperativeHandle(ref, () => ({
    showInviteModal() {
      setInviteModal(true);
    }
  }));
  console.log(orderItems)
  return (
    <Modal className="modal fade" id="exampleModal1" style={{height:'700px'}} size="l" show={inviteModal} onHide={() => setInviteModal(false)} centered>
    <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mt-3">
             
              <div className="card-body" ref={componentRef}  dir="rtl">
              <div className="row">
              <div className="col-xl-12" > 
                <center><img src={logo} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info">
                  <h2 style={{ textAlign: "center" }}>صحبة طببة</h2>
                </div>
              </div>
            </div>
                 
                <div className="table-responsive">
                <table className="table table-bordered verticle-middle table-responsive-sm" style={{ marginBottom:'0px',borderCollapse: "unset", borderWidth: '1 px' }}>
                
                  <tbody>
                <tr style={{width:'100%'}}>
                <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>الزبون  :{order.CustomerName}</td>

                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>الهاتف    :{order.PhoneNumber}-{order.PhoneNumber1}-{order.PhoneNumber2}</td>

                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>التاريخ:{order.OrderDate}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>  تاريخ التسليم : {order.DeliveryDate} </td>

                  </tr><tr>
                  <td   style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>نوع الفاتورة : نهائية </td>
                  <td   style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>الصالة : {order.HallName}  </td>

                  </tr>
                  </tbody>
                  </table>
                  <table className="table table-bordered verticle-middle table-responsive-sm" style={{marginBottom:'0px',marginTop:"1px"}}>
                    <thead>
                    
                      <tr>
                        <th className="center">#</th>
                        <th>الصنف</th>
                         <th className="right">سعر القطعة</th>
                        <th className="center">الكمية</th>
                        <th className="right">المجموع</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                      {orderItems.map((item, ind)=>(
                                                    <tr className="center" key={ind}>
                                                        <td className="center">{ind}
                                                       </td>
                                                        <td className="left strong">{item.Name}  
                                                       </td>	
                                                        
                                                       <td className="right">{item.ItemPrice}</td>

                                                        <td className="center">{item.Quantity}</td>
                                                        <td lassName="right">{item.Quantity*item.ItemPrice}د</td>
                                                    </tr>  
                                                ))}
                       
                 
                    </tbody>
                    <tbody>
                    
                    {toolItems.map((item, ind)=>(
                                                  <tr className="center" key={ind}>
                                                      <td className="center">{ind}
                                                     </td>
                                                      <td className="left strong">{item.TName}  
                                                     </td>	
                                                      
                                                     <td className="right">{item.TItemPrice}</td>

                                                      <td className="center">{item.Quantity}</td>
                                                      <td lassName="right">{item.Quantity*item.TItemPrice}د</td>
                                                  </tr>  
                                              ))}
                     
               
                  </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-5 mt-0 pt-0 "  >
                    <table className="table " style={{borderleft: '#E6E6E6 solid 1px',float:'right',
  marginleft: "0px"}}>
                      <tbody>
                      <tr>
                          <td className="left">
                            <strong>سعر التزين</strong>
                          </td>
                          <td className="right">
                            <strong>{order.DecoratePrice}</strong>
                            <br />
                           
                          </td>
                          </tr>  <tr>
                          <td className="left">
                            <strong>سعر التوصيل</strong>
                          </td>
                          <td className="right">
                            <strong>{order.DelPrice}</strong>
                            <br />
                           
                          </td>
                          </tr>
                        
                        <tr>
                          <td className="left">
                            <strong>التخفيض</strong>
                          </td>
                          <td className="right">{order.DiscountAmount}</td>
                        </tr>
                        
                        <tr>
                          <td className="left">
                            <strong>الصافي</strong>
                          </td>
                          <td className="right">
                          
                            <strong>
                              {order.TotalAmount}
                            </strong>
                               <br />
                           
                          </td>
                          
                        </tr>
                        
                        <tr>
                          <td className="left">
                            <strong>العربون</strong>
                          </td>
                          <td className="right">
                            <strong>{order.Prepay}</strong>
                            <br />
                           
                          </td>
                          </tr>
                          <tr>
                          <td className="left">
                            <strong>الباقي</strong>
                          </td>
                          <td className="right">
                            <strong>{order.TotalAmount-order.Prepay}</strong>
                            <br />
                           
                          </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          
         <div className="modal-footer">
            <button  className="btn btn-primary m-2" onClick={generatePDF}>طباعه الفاتورة</button>
 
          </div> </div>  </div>
          
        </div>
      </div>
   
    </Modal>
  );
});

export default Invoice;
