import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

function YourComponent() {
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [outcomeData, setOutcomeData] = useState([]);
  const [totalAmountSum, setTotalAmountSum] = useState(0);
  const [prepayAmountSum, setPrepayAmountSum] = useState(0);
  const [restValue, setRestValue] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [year, setYear] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this value for pagination

  const arabicMonthNames = [
    "يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
    "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
  ];

  const componentRef = useRef();

  const generatePDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Userdata",
  });

  useEffect(() => {
    // Fetch data from the API
    axios.get("https://suhba.server.com.ly/back/api/getTotalAmountByMonth")
      .then(response => {
        const data = response.data;
        setOutcomeData(data);
        setFilteredData(data);

        // Calculate total amount and prepay amount sums
        const totalAmountSum = data.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
        const prepayAmountSum = data.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
        setTotalAmountSum(totalAmountSum);
        setPrepayAmountSum(prepayAmountSum);
        setRestValue(totalAmountSum - prepayAmountSum);
      })
      .catch(error => {
        console.error("Error fetching outcome data:", error);
      });
  }, []);

  const handleSearch = () => {
    // Filter outcomeData based on startMonth, endMonth, and year
    const filtered = outcomeData.filter(item => {
      const month = item.month;
      const itemYear = item.year;
      const yearFilter = year ? itemYear === parseInt(year) : true;

      const start = parseInt(startMonth, 10) || 1;
      const end = parseInt(endMonth, 10) || 12;
      const monthFilter = endMonth === '' ? month === start : month >= start && month <= end;

      return monthFilter && yearFilter;
    });

    setFilteredData(filtered);

    // Calculate total amount and prepay amount sums from filtered data
    const totalAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.total_amount), 0);
    const prepayAmountSum = filtered.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0);
    setTotalAmountSum(totalAmountSum);
    setPrepayAmountSum(prepayAmountSum);
    setRestValue(totalAmountSum - prepayAmountSum);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const handleClearSearch = () => {
    setStartMonth('');
    setEndMonth('');
    setYear('');
    setFilteredData(outcomeData);
    setTotalAmountSum(outcomeData.reduce((acc, item) => acc + parseFloat(item.total_amount), 0));
    setPrepayAmountSum(outcomeData.reduce((acc, item) => acc + parseFloat(item.prepay_amount), 0));
    setRestValue(totalAmountSum - prepayAmountSum);
    setCurrentPage(1); // Reset to first page
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-3" style={{ display: 'flex' }}>
          <label htmlFor="startMonth" className="form-label ms-2 mt-1">من</label>
          <select
            className="default-select form-control"
            id="startMonth"
            value={startMonth}
            onChange={e => setStartMonth(e.target.value)}
          >
            <option value="">اختر</option>
            {arabicMonthNames.map((month, index) => (
              <option key={index} value={index + 1}>{month}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3" style={{ display: 'flex' }}>
          <label htmlFor="endMonth" className="form-label ms-2 mt-1">ألى</label>
          <select
            className="default-select form-control"
            id="endMonth"
            value={endMonth}
            onChange={e => setEndMonth(e.target.value)}
          >
            <option value="">اختر</option>
            {arabicMonthNames.map((month, index) => (
              <option key={index} value={index + 1}>{month}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3" style={{ display: 'flex' }}>
          <label htmlFor="startMonth" className="form-label ms-2 mt-1">السنة</label>
          <input
            className="form-control"
            type="text"
            value={year}
            placeholder="اختر السنة"
            onChange={e => setYear(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary btn-sm me-1" onClick={handleSearch}>ابحت</button>
          <button className="btn btn-secondary btn-sm me-1" onClick={handleClearSearch}>الغاء</button>
        </div>
      </div>

      <div className="card-body p-0" ref={componentRef} dir="rtl">
        <div className="row mb-3" style={{ display: 'flex' }}>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <label htmlFor="startMonth" style={{ fontSize: '15px', fontWeight: 'bold' }}> اجمالي الدخل :</label>
            <div style={{ color: 'blue' }}><strong>{totalAmountSum}</strong></div>
          </div>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <label style={{ fontSize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> اجمالي العرابين :</label>
            <div style={{ color: 'red' }}><strong>{prepayAmountSum}</strong></div>
          </div>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <label style={{ fontSize: '15px', fontWeight: 'bold' }} htmlFor="startMonth"> صافي الدخل :</label>
            <div style={{ color: 'green' }}><strong>{restValue}</strong></div>
          </div>
        </div>

        <div className="table-responsive active-projects dlab-scroll my-2 mx-2">
          <table className="table">
            <thead>
              <tr>
                <th>الشهر</th>
                <th>السنة</th>
                <th>اجمالي الدخل</th>
                <th>أجمالي العرابين</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{arabicMonthNames[item.month - 1]}</td>
                  <td>{item.year}</td>
                  <td>{item.total_amount}</td>
                  <td>{item.prepay_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
 </div>
 <div className="text-center  ">
 <div className="filter-pagination  mt-3">          <button
            className="btn btn-secondary btn-sm"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            السابق
          </button>
          <span>صفحة {currentPage} من {totalPages}</span>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            التالي
          </button>
        </div>
        </div>


      <div className="modal-footer">
        <button className="btn btn-primary m-2" onClick={generatePDF}>طباعه  </button>
      </div>
    </div>
  );
}

export default YourComponent;
